<script setup>
import { defineProps, computed } from 'vue'
import { useStore } from '../../stores/orderShipment.js'

// Store
const store = useStore()

// Props
const props = defineProps({
  step: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

// Computed
const selected = computed(() => {
  return store.step == props.index + 1
})

const checked = computed(() => {
  if (store.orderShipmentPlaced)
    return true

  return props.index + 1 < store.step
})

// Methods
const setStep = (value) => {
  store.setCurrentStep(value)
}
</script>

<template>
  <div
    class="step d-flex py-3"
    :class="{ selected: selected, checked: checked}"
  >
    <div class="step-number me-2">
      <div v-if="checked">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-check"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          stroke-width="4"
          stroke="white"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            stroke="none"
            d="M0 0h24v24H0z"
            fill="none"
          />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
      <div v-else>
        {{ index + 1 }}
      </div>
    </div>

    <div class="flex-grow-1">
      {{ step.title }}
    </div>

    <a
      v-if="checked && !store.orderShipmentPlaced"
      href="#"
      class="set-step"
      @click.prevent="setStep(index + 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-pencil"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path
          stroke="none"
          d="M0 0h24v24H0z"
          fill="none"
        />
        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
        <line
          x1="13.5"
          y1="6.5"
          x2="17.5"
          y2="10.5"
        />
      </svg>
    </a>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../assets/stylesheets/partials/_variables.scss';
  .step {
    font-size: 1.4rem;

    .step-number {
      position: relative;
      display: inline-block;
      border-radius: 4rem;
      width: 2.6rem;
      height: 2.6rem;
      text-align: center;
      line-height: 2.6rem;
      background-color: lighten(black, 30%);
      color: white;

      &:before {
        display: block;
        position: absolute;
        top: -18px;
        left: 50%;
        border: 1px solid lighten(black, 85%);
        width: 0;
        height: 16px;
        margin-left: -1px;
        content: '';
        z-index: 1;

      }
    }

    &.selected {
      font-weight: 500;
      color: $milano-red;

      .step-number {
        background-color: $milano-red;
      }
    }

    &.checked {
      color: $fun-green;

      .step-number {
        background-color: $fun-green;
      }
    }

    .set-step {
      color: lighten(black, 60%);

      &:hover {
        color: lighten(black, 30%);

        svg {
          fill: transparent;
        }
      }
    }
  }
</style>
