<script setup>
import { i18n } from '../../../lib/i18n'
import { onMounted, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useStore } from '../../../stores/orderShipment.js'
import TabNav from '../TabNav.vue'
import TabPane from '../TabPane.vue'

const I18n = i18n

// Store
const store = useStore()

// Watch
watch(() => store.orderShipment.third_party_shipping_b2b_customer_id, () => {
  store.orderShipment.third_party_shipping_b2b_address_id = null
})

watch(() => store.selectedShippingInformationTab, (newValue, oldValue) => {
  // Reset selections only when oldValue has something, to avoid change on page load
  if (Object.keys(oldValue).length > 0) {
    store.orderShipment.b2b_shipping_address_id = null
    store.orderShipment.third_party_shipping_b2b_customer_id = null
    store.orderShipment.third_party_shipping_b2b_address_id = null
  }
})

// Watch for any changes in b2b shipping address in order to reload the transport options
watch(() => store.orderShipment.b2b_shipping_address_id, () => {
  store.loadShippingMethods()
})

watch(() => store.orderShipment.third_party_shipping_b2b_address_id, () => {
  store.loadShippingMethods()
})

// Methods
const setTab = (value) => {
  store.setShippingInformationTab(value)
}

// Mounted
onMounted(() => {
  store.loadThirdPartyShippingCustomers()
  store.loadShippingMethods()
})
</script>

<template>
  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="py-4">
        <ul class="nav nab-tabs d-flex fs-1">
          <TabNav
            v-for="tab in store.shippingInformationTabs"
            :key="tab.code"
            :tab="tab"
            :selected="store.selectedShippingInformationTab"
            @select="setTab"
          />
        </ul>
        <div class="tab-content pt-5 pb-3">
          <TabPane
            v-for="tab in store.shippingInformationTabs"
            :key="tab.code"
            :tab="tab"
            :selected="store.selectedShippingInformationTab"
          >
            <template v-if="tab.code == 'my-address'">
              <label class="pb-2">{{ I18n.t("order_shipments.steps.step4.labels.b2b_shipping_address_id") }}</label>
              <Multiselect
                v-model="store.orderShipment.b2b_shipping_address_id"
                :options="store.addresses"
                track-by="description"
                label="description"
                value-prop="id"
                :placeholder="I18n.t('order_shipments.steps.step4.placeholders.b2b_shipping_address_id')"
                :searchable="true"
              />
            </template>

            <template v-if="tab.code == 'third-party-address'">
              <div class="row">
                <div class="col-6">
                  <label class="pb-2">
                    {{ I18n.t("order_shipments.steps.step4.labels.third_party_shipping_b2b_customer_id") }}
                  </label>
                  <Multiselect
                    v-model="store.orderShipment.third_party_shipping_b2b_customer_id"
                    :options="store.thirdPartyShippingCustomers"
                    track-by="title"
                    label="title"
                    value-prop="id"
                    :placeholder="I18n.t('order_shipments.steps.step4.placeholders.third_party_shipping_b2b_customer_id')"
                    :searchable="true"
                  />
                </div>
                <div class="col-6">
                  <label
                    v-if="store.thirdPartyCustomerAddresses.length > 0"
                    class="pb-2"
                  >
                    {{ I18n.t("order_shipments.steps.step4.labels.third_party_shipping_b2b_address_id") }}
                  </label>
                  <Multiselect
                    v-if="store.thirdPartyCustomerAddresses.length > 0"
                    v-model="store.orderShipment.third_party_shipping_b2b_address_id"
                    :options="store.thirdPartyCustomerAddresses"
                    track-by="description"
                    label="description"
                    value-prop="id"
                    :placeholder="I18n.t('order_shipments.steps.step4.placeholders.third_party_shipping_b2b_address_id')"
                    :searchable="true"
                  />
                </div>
              </div>
            </template>
          </TabPane>
        </div>
      </div>
      <hr class="my-4">
      <div class="py-4">
        <h3 class="pb-3">
          {{ I18n.t("order_shipments.steps.step4.labels.shipping_method") }}
        </h3>

        <div
          v-if="store.shippingMethods == null"
          class="text-center"
        >
          <span class="warning">
            {{ I18n.t("order_shipments.steps.step4.texts.shipping_methods.notice") }}
          </span>
        </div>
        <div
          v-else-if="store.shippingMethodsLoading"
          class="text-center"
        >
          <vue-loaders-ball-beat
            color="#666"
            scale="1"
          />
        </div>
        <div
          v-else-if="store.shippingMethods.length == 0"
          class="text-center no-results"
        >
          {{ I18n.t("order_shipments.steps.step4.texts.shipping_methods.empty") }}
        </div>
        <div
          v-else
          class="checkbox-group"
        >
          <div
            v-for="transportOption in store.shippingMethods"
            :key="transportOption.id"
            class="form-check checkbox-wrapper p-0 mb-2"
          >
            <label class="form-check-label p-3">
              <input
                v-model="store.orderShipment.shipping_method"
                type="radio"
                class="form-check-input mx-3"
                name="transport-option"
                :value="transportOption"
              >
              {{ transportOption.shipping_method_description }}
              -
              {{ transportOption.transporter_name }}
            </label>
          </div>
        </div>
      </div>
      <hr class="my-4">
      <div class="py-4">
        <h3 class="pb-3">
          {{ I18n.t("order_shipments.steps.step4.labels.packaging_and_shipping_instructions") }}
        </h3>

        <textarea
          v-model="store.orderShipment.packaging_and_shipping_instructions"
          rows="4"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../../assets/stylesheets/partials/_variables.scss';

  .warning {
    font-size: 1.4rem;
    font-style: italic;
    color: lighten(black, 60%);
  }

  .no-results {
    font-size: 1.4rem;
    color: $crimso;
  }
</style>
