export default {
  "en": {
    "history_order_lines_table": {
      "order_date": "Order Date",
      "code": "Code",
      "lot_code": "LOT Code",
      "barcode": "Barcode",
      "quantity": "Quantity",
      "price": "Price",
      "line_gross_discount": "Total Discount",
      "line_net_value": "Line Total",
      "status": "Status"
    },
    "processed_order_lines_table": {
      "tab_pane": {
        "viewing": "Viewing",
        "of": "of",
        "records": "records",
        "new_shipment_button": "Create Shipment »"
      },
      "tab_nav": {
        "title": {
          "rsv": "Confirmed in Stock",
          "inb": "Confirmed on Order",
          "soc": "Confirmed from Inbound",
          "bor": "Backorders",
          "cnc": "Cancelled"
        },
        "tooltip_decription": {
          "rsv": "This is where you can find your Confirmed quantities that are expected to arrive in our Logistics Center in the next few days.",
          "inb": "This is where you can find your Confirmed quantities that are on Order to our suppliers without any confirmed inbound date to our Logistics Center.",
          "soc": "This is where you can build your shipment(s) by selecting Confirmed quantities that are in Stock and reserved for you for immediate dispatch.",
          "bor": "This is where you can find your current Backordered quantities which correspond to quantities that can’t be confirmed at the moment because they are out-of-stock and without any pending Inbound quantities or on order to our suppliers. ",
          "cnc": "This is where you can find all order lines that you or our system has cancelled followed by the reason of cancelation."
        }
      },
      "per": {
        "per": "Per"
      },
      "search": {
        "placeholder": "Search by product description, code or barcode"
      },
      "table": {
        "heading": {
          "product": "Product",
          "barcode": "Barcode",
          "price": "Price",
          "initial_quantity": "Initial Quantity",
          "quantity": "Quantity",
          "confirmed_quantity": "Confirmed Quantity",
          "discount": "Discount (%)",
          "net_value": "Net Value",
          "vat": "VAT",
          "total_value": "Total Value",
          "date": "Date"
        },
        "body": {
          "no_records": "No records available."
        }
      }
    },
    "orders": {
      "show": {
        "confirm_dialog": {
          "title": "A small confirmation :)",
          "text": "By continuing your order will be sent to Enarxis.",
          "cancelButtonText": "Cancel",
          "confirmButtonText": "Yes, I want to checkout!"
        }
      }
    },
    "order_shipments": {
      "index": {
        "destroy": {
          "confirm_dialog": {
            "title": "Delete this shipment?",
            "text": "Are you sure you want to delete this shipment?",
            "cancelButtonText": "Cancel",
            "confirmButtonText": "Yes, I want to delete it!"
          }
        }
      },
      "shipping_information_tabs": {
        "my_address": {
          "title": "My Address"
        },
        "third_party_address": {
          "title": "Third Party Address",
          "tooltip": "In case you select this option, you may choose your shipment to be delivered to another company accompanied by a Delivery Note while you will only receive the Invoice that corresponds to this shipment. In order to be able to use this option, you will have to contact us and provide the details of the other company so that we are able to verify, register its information in our ERP and connect it to your account."
        }
      },
      "order_shipment_pane": {
        "heading_shipment_items": "Shipment Items"
      },
      "shipmentLineTable": {
        "product": "Product",
        "codes": "Codes",
        "attributes": "Attributes",
        "quantity_available_for_shipping": "Quantity Available <br> for Shipping",
        "quantity_to_ship": "Quantity to Ship",
        "price": "Price"
      },
      "steps": {
        "step1": {
          "title": "Shipment Quantities",
          "heading": "Shipment Quantities per Item",
          "subheading": "Please select the final quantities you wish to create shipment for.",
          "nextButton": "Next »"
        },
        "step2": {
          "title": "Shipment Options",
          "heading": "Shipment Options & Info",
          "subheading": "Please enter your comments regarding the handling of your shipment from us.",
          "previousButton": "« Previous",
          "nextButton": "Next »",
          "labels": {
            "shipment_reference": "Enter your PO number» or «Shipment Reference»:",
            "operating_instructions": "Operating Instructions:"
          },
          "help": {
            "operating_instructions": "Please enter your comments regarding the handling of your shipment from us."
          }
        },
        "step3": {
          "title": "Pricing Information",
          "heading": "Pricing Information",
          "previousButton": "« Previous",
          "nextButton": "Next »",
          "labels": {
            "pricing_address": "Pricing Address:",
            "pricing_method": "Pricing Method:",
            "pricing_method_1": "One invoice for the whole cargo",
            "pricing_method_2": "One invoice per order reference",
            "invoice_text": "Invoice Text:"
          },
          "tooltips": {
            "pricing_method": "In case you select this option, invoicing will produce one invoice for each «Order Reference» you entered just before you created each order. Only one Delivery Note will be printed which will accompany the entire shipment."
          },
          "help": {
            "invoice_text": "Enter the text you want to print on the distribution and invoicing documents."
          }
        },
        "step4": {
          "title": "Shipping Information",
          "heading": "Shipping Information",
          "previousButton": "« Previous",
          "nextButton": "Next »",
          "labels": {
            "b2b_shipping_address_id": "Shipping Address:",
            "third_party_shipping_b2b_customer_id": "Ship To:",
            "third_party_shipping_b2b_address_id": "Address:",
            "shipping_method": "Shipping Method",
            "packaging_and_shipping_instructions": "Packaging and Shipping Instructions"
          },
          "placeholders": {
            "b2b_shipping_address_id": "Select address..",
            "third_party_shipping_b2b_customer_id": "Select company...",
            "third_party_shipping_b2b_address_id": "Select address.."
          },
          "texts": {
            "shipping_methods": {
              "notice": "Please select a shipping address first to see available shipping methods.",
              "empty": "Couldn't find any available shipping methods."
            }
          }
        },
        "step5": {
          "title": "Payment Method",
          "heading": "Select Payment Term",
          "previousButton": "« Previous",
          "nextButton": "Calculate Commerial Policies & Review »",
          "labels": {
            "payment_method": "Payment Method:"
          }
        },
        "step6": {
          "title": "Cargo Summary",
          "heading": "Cargo Summary Review",
          "subheading": "Please review the cargo before sending it.",
          "previousButton": "« Previous",
          "nextButton": "Complete Cargo »",
          "labels": {
            "cargo_information": "Cargo Information",
            "shipping_information": "Shipping Information",
            "pricing_information": "Pricing Information",
            "shipping_method": "Shipping Method",
            "pricing_method": "Pricing Method",
            "invoice_text": "Invoice Text",
            "payment_method": "Payment Method",
            "order_reference": "PO Number/Reference",
            "operating_instructions": "Operating Instructions",
            "shipping_address": "Shipping Address",
            "pricing_address": "Pricing Address",
            "payment_information": "Payment Information"
          }
        },
        "step7": {
          "title": "Cargo Completion",
          "heading": "",
          "subheading": "Cargo has been successfully created with code {{code}}!",
          "text1": "If you have any question about your shipment, you can contact us at +30 210 9011900 or alternatively via email at info@enarxis.eu",
          "text2": "We will inform you when the shipment has been dispatched."
        }
      },
      "order_shipment_totals": {
        "transportation": "Transportation Costs",
        "recycling_fees": "Recycling Fees",
        "clearance": "Clearance Fees",
        "insurance": "Insurance Fees",
        "banking_expenses": "Banking Expenses",
        "net_value": "Net Value",
        "discount": "Discount",
        "total": "Total"
      },
      "review_summary": {
        "confirm_dialog": {
          "title": "Μια μικρή επιβεβαίωση :)",
          "text": "Με την ενέργεια σας θα αποσταλεί το φορτίο στην Enarxis.",
          "cancelButtonText": "Ακύρωση",
          "confirmButtonText": "Ναι, επιθυμώ να το στείλω!"
        }
      }
    }
  },
  "el": {
    "history_order_lines_table": {
      "order_date": "Ημερομηνία Παραγγελίας",
      "code": "Κωδικός",
      "lot_code": "Κωδικός LOT",
      "barcode": "Barcode",
      "quantity": "Ποσότητα",
      "price": "Τιμή",
      "line_gross_discount": "Συνολική Έκπτωση",
      "line_net_value": "Αξία Γραμμής",
      "status": "Κατάσταση"
    },
    "processed_order_lines_table": {
      "tab_pane": {
        "viewing": "Εμφανίζονται",
        "of": "από",
        "records": "αποτελέσματα",
        "new_shipment_button": "Δημιουργία Φορτίου »"
      },
      "tab_nav": {
        "title": {
          "rsv": "Επιβεβαιωμένα σε Απόθεμα",
          "inb": "Επιβεβαιωμένα σε Παραγγελία",
          "soc": "Επιβεβαιωμένα σε Προμηθευτή",
          "bor": "Backorders",
          "cnc": "Ακυρωμένα"
        },
        "tooltip_decription": {
          "rsv": "Εδώ μπορείτε να «χτίσετε» τα Φορτία σας επιλέγοντας ποσότητες που είναι επιβεβαιωμένες και δεσμευμένες για εσάς από το διαθέσιμο Stock.",
          "inb": "Eδώ μπορείτε να παρακολουθείτε τις Επιβεβαιωμένες Ποσότητες Παραγγελιών σας οι οποίές αναμένεται να αφιχθούν στο Κέντρο διανομής μας τις αμέσως επόμενες ημέρες.",
          "soc": "Εδώ μπορείτε να παρακολουθείτε τις Επιβεβαιωμένες Ποσότητες Παραγγελιών σας οι οποίες είναι σε παραγγελία στους προμηθευτές μας χωρίς να υπάρχει επιβεβαιωμένη Ημερομηνία Άφιξης στο Κέντρο Διανομής μας.",

          "bor": "Εδώ μπορείτε να παρακολουθείτε τις Ποσότητες Παραγγελιών σας οι οποίες είναι σε κατάσταση Backorder δεν υπάρχει διαθέσιμο stock ούτε αντίστοιχες παραγγελίες προτς τους προμηθευτές μας.",
          "cnc": "Εδώ μπορείτε να παρακολουθείτε όλες τις γραμμές και τις ποσότητες παραγγελιών που εσείς ή το σύστημά μας έχει ακυρώσει παράλληλα με το λόγο για την ακύρωση αυτή."
        }
      },
      "per": {
        "per": "Ανά"
      },
      "search": {
        "placeholder": "Αναζήτηση βάσει περιγραφής, κωδικού ή barcode"
      },
      "table": {
        "heading": {
          "product": "Προϊόν",
          "barcode": "Barcode",
          "price": "Τιμή",
          "initial_quantity": "Αρχική Ποσότητα",
          "quantity": "Ποσότητα",
          "confirmed_quantity": "Επιβεβαιωμένη Ποσότητα",
          "discount": "Έκπτωση",
          "net_value": "Καθαρή Αξία",
          "vat": "Φ.Π.Α.",
          "total_value": "Τελική Αξία",
          "date": "Ημερομηνία"
        },
        "body": {
          "no_records": "Δεν υπάρχουν διαθέσιμες εγγραφές."
        }
      }
    },
    "orders": {
      "show": {
        "confirm_dialog": {
          "title": "Μια μικρή επιβεβαίωση :)",
          "text": "Με την ενέργεια σας θα αποσταλεί η παραγγελία στην Enarxis.",
          "cancelButtonText": "Ακύρωση",
          "confirmButtonText": "Ναι, επιθυμώ να την στείλω!"
        }
      }
    },
    "order_shipments": {
      "index": {
        "destroy": {
          "success": {
            "title": "Η διαγραφή ήταν επιτυχής!"
          },
          "confirm_dialog": {
            "title": "Να διαγραφεί αυτό το φορτίο;",
            "text": "Η διαγραφή είναι οριστική και μπορεί να πραγματοποιηθεί μόνο αν ένα φορτίο δεν έχει αποσταλλεί ακόμα στην Enarxis.",
            "cancelButtonText": "Ακύρωση",
            "confirmButtonText": "Ναι, θέλω να διαγραφεί!"
          }
        }
      },
      "shipping_information_tabs": {
        "my_address": {
          "title": "Διεύθυνση μου"
        },
        "third_party_address": {
          "title": "Διεύθυνση Τρίτου",
          "tooltip": "Στην περίπτωση που επιλεγεί, μπορείτε να επιλέξετε το φορτίο να παραδοθεί με Δελτίο Αποστολής σε κάποιον τρίτο ενώ εσείς θα λάβετε μόνο το τιμολόγιο που θα αφορά το Φορτίο. Για να επιλέξετε κάποιον τρίτο, θα πρέπει προηγουμένως να επικοινωνήσετε μαζί μας προκειμένου να μας παρέχετε τα στοιχεία του προς έλεγχο, καταχώρηση και σύνδεση με το λογαριασμό σας."
        }
      },
      "order_shipment_pane": {
        "heading_shipment_items": "Είδη Φορτίου"
      },
      "shipment_lines_table": {
        "product": "Προϊόν",
        "codes": "Κωδικοί",
        "attributes": "Χαρακτηριστικά",
        "quantity_available_for_shipping": "Ποσότητα Διαθεσιμη<br>για Αποστολή",
        "quantity_to_ship": "Επιλεγμένη Ποσότητα",
        "price": "Τιμή"
      },
      "steps": {
        "step1": {
          "title": "Ποσότητες Φόρτωσης",
          "heading": "Επιλογή Ποσοτήτων Φόρτωσης ανά Είδος",
          "subheading": "Παρακαλούμε διαλέξτε τις τελικές ποσότητες για τις οποίες θέλετε να δημιουργήσετε φορτίο.",
          "previousButton": "",
          "nextButton": "Επόμενο »"
        },
        "step2": {
          "title": "Παράμετροι Φορτίου",
          "heading": "Επιλογές και Πληροφορίες Φορτίου",
          "subheading": "Εισάγετε τα ενδεχόμενα σχόλιά σας σχετικά με χειρισμό του Φορτίου σας από εμάς.",
          "previousButton": "« Προηγούμενο",
          "nextButton": "Επόμενο »",
          "labels": {
            "shipment_reference": "Εισάγετε το «PO Number» ή «Shipment Reference»:",
            "operating_instructions": "Οδηγίες Χειρισμού:"
          },
          "help": {
            "operating_instructions": "Εισάγετε τα ενδεχόμενα σχόλιά σας σχετικά με χειρισμό του Φορτίου σας από εμάς."
          }
        },
        "step3": {
          "title": "Πληροφορίες Τιμολόγησης",
          "heading": "Πληροφορίες Τιμολόγησης",
          "previousButton": "« Προηγούμενο",
          "nextButton": "Επόμενο »",
          "labels": {
            "pricing_address": "Διεύθυνση Τιμολόγησης:",
            "pricing_method": "Τρόπος Τιμολόγησης:",
            "pricing_method_1": "Ένα Τιμολόγιο για όλο το Φορτίο",
            "pricing_method_2": "Ένα Τιμολόγιο ανά Κωδικό Παραγγελίας",
            "invoice_text": "Κείμενο Τιμολογίου:"
          },
          "tooltips": {
            "pricing_method": "Στην περίπτωση που επιλεγεί, η τιμολόγηση θα γίνει ανά Κωδικό Παραγγελίας που έχετε εισάγει στο Πεδίο «Κωδικός Παραγγελίας» κατά τη δημιουργία της. Το Δελτίο Αποστολής θα είναι μόνο ένα και θα συνοδεύει ολόκληρο το φορτίο."
          },
          "help": {
            "invoice_text": "Εισάγετε το κείμενο που επιθυμείτε να εκτυπωθεί στο τιμολόγιο / δελτίο αποστολής."
          }
        },
        "step4": {
          "title": "Πληροφορίες Αποστολής",
          "heading": "Πληροφορίες Αποστολής",
          "previousButton": "« Προηγούμενο",
          "nextButton": "Επόμενο »",
          "labels": {
            "b2b_shipping_address_id": "Διεύθυνση Αποστολής:",
            "third_party_shipping_b2b_customer_id": "Αποστολή Σε:",
            "third_party_shipping_b2b_address_id": "Διεύθυνση:",
            "shipping_method": "Τρόπος Αποστολής",
            "packaging_and_shipping_instructions": "Οδηγίες Συσκευασίας & Αποστολής"
          },
          "placeholders": {
            "b2b_shipping_address_id": "Επιλέξτε διεύθυνση...",
            "third_party_shipping_b2b_customer_id": "Επιλέξτε εταιρεία...",
            "third_party_shipping_b2b_address_id": "Επιλέξτε διεύθυνση...",
          },
          "texts": {
            "shipping_methods": {
              "notice": "Παρακαλούμε επιλέξτε πρώτα διεύθυνση αποστολής για να δείτε τους διαθέσιμους τρόπους αποστολής.",
              "empty": "Δεν βρέθηκαν διαθέσιμοι τρόποι αποστολής."
            }
          }
        },
        "step5": {
          "title": "Τρόπος Πληρωμής",
          "heading": "Τρόπος Πληρωμής",
          "previousButton": "« Προηγούμενο",
          "nextButton": "Υπολογισμός Εμπορικών Πολιτικών & Σύνοψη »",
          "labels": {
            "payment_method": "Τρόπος Πληρωμής:"
          }
        },
        "step6": {
          "title": "Σύνοψη Φορτίου",
          "heading": "Σύνοψη Φορτίου",
          "previousButton": "« Προηγούμενο",
          "nextButton": "Αποστολή Φορτίου σε Enarxis »",
          "subheading": "Παρακαλούμε επιβεβαιώστε τα στοιχεία του φορτίου πριν την αποστολή.",
          "labels": {
            "cargo_information": "Πληροφορίες Φορτίου",
            "shipping_information": "Πληροφορίες Αποστολής",
            "pricing_information": "Πληροφορίες Τιμολογίου",
            "shipping_method": "Τρόπος Αποστολής",
            "pricing_method": "Τρόπος Τιμολόγησης",
            "invoice_text": "Κείμενο Τιμολογίου",
            "payment_method": "Τρόπος Πληρωμής",
            "order_reference": "PO Number/Κωδικός Παραγγελίας",
            "operating_instructions": "Οδηγίες Χειρισμού",
            "shipping_address": "Διεύθυνση Αποστολής",
            "pricing_address": "Διεύθυνση Τιμολόγησης",
            "payment_information": "Πληροφορίες Πληρωμής"
          }
        },
        "step7": {
          "title": "Αποστολή Φορτίου",
          "heading": "",
          "subheading": "To φορτίο δημιουργήθηκε επιτυχώς με κωδικό {{code}}!",
          "text1": "Aν έχετε οποιαδήποτε απορία σχετικά με το φορτίο σας, μπορείτε να επικοινωνήσετε μαζί μας στο τηλέφωνο 210 9011900 ή εναλλακτικά μέσω email στο info@enarxis.eu",
          "text2": "Θα σας ενημερώσουμε μόλις το φορτίο αποσταλεί επιτυχώς."
        }
      },
      "order_shipment_totals": {
        "transportation": "Κόστος Μεταφοράς",
        "recycling_fees": "Τέλη Ανακύκλωσης",
        "clearance": "Εκτελωνισμός",
        "insurance": "Ασφάλεια",
        "banking_expenses": "Έξοδα Τραπέζης",
        "net_value": "Καθαρή Αξία",
        "discount": "Έκπτωση",
        "total": "Σύνολο"
      },
      "review_summary": {
        "confirm_dialog": {
          "title": "Μια μικρή επιβεβαίωση :)",
          "text": "Με την ενέργεια σας θα αποσταλεί το φορτίο στην Enarxis.",
          "cancelButtonText": "Ακύρωση",
          "confirmButtonText": "Ναι, επιθυμώ να το στείλω!"
        }
      }
    }
  }
}
