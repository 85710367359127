<script setup>
import { i18n } from "../../../lib/i18n"
import { ref, onMounted, defineEmits } from 'vue'
import { onClickOutside, useStorage } from '@vueuse/core'

// Data
const I18n = i18n
const options = [
  100,
  200,
  400
]
const target = ref(null)
let displayOptions = ref(false)
let selected = ref(null)

// Emits
const emit = defineEmits(['select'])

// Close dropdown when clicked outside
onClickOutside(target, () => displayOptions.value = false)

// Methods
const toggle = () => {
  displayOptions.value = !displayOptions.value
}

const select = (value) => {
  storedOption.value = selected.value = value
  toggle()
  emit('select', value)
}

// Store last selected value
const storedOption = useStorage('selected-per-option', options[0])

// Mounted
onMounted(() => {
  selected.value = storedOption.value
  emit('select', storedOption.value)
})
</script>

<template>
  <div
    ref="target"
    class="catalog__toolbar-per d-inline-block d-flex align-items-center pb-2"
  >
    <span class="me-3 d-none d-md-inline">
      {{ I18n.t("processed_order_lines_table.per.per") }}
    </span>
    <div class="dropdown catalog__dropdown">
      <button
        aria-expanded="false"
        class="btn dropdown-toggle text-start p-2"
        data-bs-toggle="dropdown"
        type="button"
        @click="toggle"
      >
        <span class="me-5">{{ selected }}</span>
      </button>

      <ul
        v-if="displayOptions"
        class="dropdown-menu gy-per"
      >
        <li
          v-for="number in options"
          :key="number"
        >
          <a
            class="dropdown-item "
            href="#"
            @click="select(number)"
          >
            {{ number }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  .dropdown-menu {
    display: block;
  }
}
</style>