import { API_PATH } from '../lib/constants'
import axios from 'axios'

axios.defaults.headers.common['x-csrf-token'] = !!document.querySelector('meta[name="csrf-token"]') && document.querySelector('meta[name="csrf-token"]').content
axios.defaults.headers.common['Authorization'] = window.api_token
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest'

export function getAddresses() {
  return axios.get(`${API_PATH}/b2b/addresses`)
}

export function getThirdPartyShippingCustomers() {
  return axios.get(`${API_PATH}/b2b/third_party_shipping_customers`)
}

export function getHistoryOrderLines(productId) {
  return axios.get(`${API_PATH}/history_order_lines`, {
    params: {
      product_id: productId
    }
  })
}

export function getProcessedOrderLines() {
  return axios.get(`${API_PATH}/processed_order_lines`)
}

export function getOrderShipments() {
  return axios.get(`${API_PATH}/order_shipments`)
}

export function getOrderShipment(id) {
  return axios.get(`${API_PATH}/order_shipments/${id}`)
}

export function createOrderShipment(b2b_order_shipment_lines) {
  return axios.post(`${API_PATH}/order_shipments`, {
    b2b_order_shipment: {
      b2b_order_shipment_lines
    }
  })
}

export function updateOrderShipment({ b2b_order_shipment, step }) {
  return axios.put(`${API_PATH}/order_shipments/${b2b_order_shipment.id}`, {
    b2b_order_shipment,
    step
  })
}

export function destroyOrderShipment(id) {
  return axios.delete(`${API_PATH}/order_shipments/${id}`)
}

export function getShippingMethods(b2b_customer_id, b2b_address_id) {
  return axios.get(`${API_PATH}/transport_options`, {
    params: {
      b2b_customer_id,
      b2b_address_id
    }
  })
}

export function getPaymentMethods() {
  return axios.get(`${API_PATH}/payment_methods`)
}
