<script setup>
import { ref, defineProps, onMounted } from 'vue'
import tippy from "tippy.js"

// Props
const props = defineProps({
  message: {
    type: String,
    default: ""
  }
})

const el = ref(null)

onMounted(async () => {
  tippy(el.value, {
    content: props.message
  })
})
</script>

<template>
  <a
    ref="el"
    href="#"
    class="information-tooltip"
    @click.prevent=""
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-info-circle ms-2"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="1.8"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <circle
        cx="12"
        cy="12"
        r="9"
      />
      <line
        x1="12"
        y1="8"
        x2="12.01"
        y2="8"
      />
      <polyline points="11 12 12 12 12 16 13 16" />
    </svg>
  </a>
</template>

<style lang="scss" scoped>  a.information-tooltip {
    color: lighten(black, 60%);

    &:hover {
      color: lighten(black, 30%);
    }
  }
</style>
