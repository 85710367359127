<script setup>
import { i18n } from '../../lib/i18n'
import { defineProps, defineEmits } from 'vue'
import OrderShipmentStatus from './OrderShipmentStatus.vue'

const I18n = i18n

// Props
const props = defineProps({
  orderShipments: {
    type: Array,
    required: true
  }
})

// Emit
const emit = defineEmits('destroy-line')

// Methods
const isPlaced = (orderShipment) => {
  return orderShipment.state == "placed"
}

const destroyLine = (line) => {
  emit('destroy-line', line)
}

const urlFor = (line) => {
  return `/${window.locale}/order_shipments/${line.id}`
}
</script>

<template>
  <table class="table shipment-lines-table">
    <thead>
      <tr>
        <th class="text-center p-4">
          Κωδικός
        </th>
        <th class="text-center p-4">
          Κατάσταση
        </th>
        <th class="text-center p-4">
          Διεύθυνση Τιμολόγησης
        </th>
        <th class="text-center p-4">
          Δημιουργήθηκε
        </th>
        <th class="text-center p-4">
          Ανανεώθηκε
        </th>
        <th
          v-if="!readOnly"
          class="text-center p-4"
        />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="line in orderShipments"
        :key="line.id"
      >
        <td class="text-center p-4 font-medium">
          <a :href="urlFor(line)">
            {{ line.code }}
          </a>
        </td>
        <td class="text-center p-4">
          <OrderShipmentStatus :order-shipment="line" />
        </td>
        <td class="text-center p-4 font-size-13">
          <span v-if="line.b2b_billing_address">
            {{ line.b2b_billing_address.title }}
          </span>
        </td>

        <td class="text-center p-4">
          {{ line.created_at }}
        </td>

        <td class="text-center p-4">
          {{ line.updated_at }}
        </td>
        <td class="text-center p-4">
          <a
            :href="urlFor(line)"
            class="pe-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-eye"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              />
              <circle
                cx="12"
                cy="12"
                r="2"
              />
              <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
            </svg>
          </a>

          <a
            v-if="!isPlaced(line)"
            href="#"
            @click.prevent="destroyLine(line)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-trash"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              />
              <line
                x1="4"
                y1="7"
                x2="20"
                y2="7"
              />
              <line
                x1="10"
                y1="11"
                x2="10"
                y2="17"
              />
              <line
                x1="14"
                y1="11"
                x2="14"
                y2="17"
              />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/partials/_variables.scss';

table.shipment-lines-table {
  th {
    font-size: 1.4rem;
    font-weight: 600;
    color: lighten(black, 30%);
    background-color: lighten(black, 92%);
  }

  td {
    border-bottom: 1px solid lighten(black, 95%);
  }

  tr:hover {
    td {
      background-color: lighten(black, 96%);
    }
  }

  tbody {
    th {
      font-size: 1.5rem;
      font-weight: 500;
      background-color: white;
      border-bottom: 2px solid lighten(black, 92%);
    }
  }

  .product-description {
    min-width: 200px;
  }

  .order-code {
    border-right: 1px solid lighten(black, 92%);
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .color-fun-green {
    color: $fun-green;
  }

  .color-milano-red {
    color: $milano-red;
  }

  .color-light-grey {
    color: lighten(black, 60%);
  }

  .font-size-15 {
    font-size: 1.5rem;
  }

  .font-size-14 {
    font-size: 1.4rem;
  }

  .font-size-13 {
    font-size: 1.3rem;
  }

  .font-size-12 {
    font-size: 1.2rem;
  }

  .leading-7 {
    line-height: 1.75rem;
  }

  .loading-icon {
    min-height: 200px;
  }
}
</style>