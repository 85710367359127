<script>
import { i18n } from "../../../lib/i18n"

export default {
  name: "ProcessedOrderLinesTable",
  props: {
    tab: {
      required: true,
      type: Object
    },
    loading: {
      default: true,
      type: Boolean
    },
    lines: {
      default: [],
      type: Object
    }
  },
  data() {
    return {
      I18n: i18n,
      selectAll: false,
      selected: {}
    }
  },
  computed: {
    groupedLines() {
      let lines = {}

      for (let line of this.lines) {
        lines[line.item.order_code] ||= []
        lines[line.item.order_code].push(line.item)
      }

      return lines
    },
    colspan() {
      return (this.tab.canCreateShipment) ? 11 : 9
    }
  },
  watch: {
    lines() {
      this.selected = {}
      this.selectAll = false
    },
    selected() {
      this.$emit("select", this.selected)
    }
  },
  methods: {
    doSelectAll() {
      if (this.selectAll) {
        for (let line of this.lines) {
          this.selected[line.item.id] = true
        }
      } else {
        this.selected = {}
      }
    }
  }
}
</script>

<template>
  <table class="table processed-order-lines">
    <thead>
      <tr>
        <th
          v-if="tab.canCreateShipment"
          class="p-4"
        >
          <input
            v-model="selectAll"
            type="checkbox"
            :value="true"
            @change="doSelectAll"
          >
        </th>
        <th class="p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.product") }}
        </th>
        <th class="text-center p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.barcode") }}
        </th>
        <th class="text-end p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.price") }}
        </th>
        <th class="text-center p-4">
          <span v-if="tab.canCreateShipment">
            {{ I18n.t("processed_order_lines_table.table.heading.initial_quantity") }}
          </span>
          <span v-else>
            {{ I18n.t("processed_order_lines_table.table.heading.quantity") }}
          </span>
        </th>
        <th
          v-if="tab.canCreateShipment"
          class="text-center p-4"
        >
          {{ I18n.t("processed_order_lines_table.table.heading.confirmed_quantity") }}
        </th>
        <th class="text-center p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.discount") }}
        </th>
        <th class="text-end p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.net_value") }}
        </th>
        <th class="text-end p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.vat") }}
        </th>
        <th class="text-end p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.total_value") }}
        </th>
        <th class="text-center p-4">
          {{ I18n.t("processed_order_lines_table.table.heading.date") }}
        </th>
      </tr>
    </thead>
    <tbody v-if="loading || lines.length == 0">
      <tr>
        <td
          :colspan="colspan"
          class="text-center p-4"
        >
          <div
            v-if="loading"
            class="loading-icon d-flex align-items-center justify-content-center"
          >
            <vue-loaders-pacman
              color="#ddd"
              scale="2"
              class="d-block"
            />
          </div>
          <div v-else>
            {{ I18n.t("processed_order_lines_table.table.body.no_records") }}
          </div>
        </td>
      </tr>
    </tbody>
    <tbody
      v-for="(records, orderCode) in groupedLines"
      v-else
      :key="orderCode"
    >
      <tr>
        <th
          :colspan="colspan"
          class="py-2 px-4 pt-4"
        >
          <div class="d-flex align-items-center">
            <div class="pe-3 order-code">
              #{{ orderCode }}
            </div>

            <div class="ps-3 color-light-grey font-size-12">
              Sent at {{ records[0].reference_date }}
            </div>
          </div>
        </th>
      </tr>
      <tr
        v-for="line in records"
        :key="line.id"
      >
        <td v-if="tab.canCreateShipment">
          <input
            v-model="selected[line.id]"
            type="checkbox"
            :value="true"
          >
        </td>
        <td class="p-4 product-description">
          <h4 class="font-medium font-size-15 leading-7">
            {{ line.item_description }}
          </h4>

          <div class="font-size-12 color-light-grey">
            {{ line.item_code }}
          </div>
        </td>
        <td class="text-center p-4 font-size-13">
          {{ line.barcode }}
        </td>
        <td class="text-end p-4 font-medium color-milano-red">
          {{ line.price }}
        </td>
        <td class="text-center p-4 font-semibold">
          {{ line.quantity }}
        </td>
        <td
          v-if="tab.canCreateShipment"
          class="text-center p-4 font-semibold color-fun-green"
        >
          {{ line.quantity }}
        </td>
        <td class="text-center p-4 text-nowrap">
          {{ line.discount1 }} + {{ line.discount3 }}
        </td>
        <td class="text-end p-4 font-medium">
          {{ line.net_value }}
        </td>
        <td class="text-end p-4 font-medium">
          {{ line.vat_value }}
        </td>
        <td class="text-end p-4 font-medium">
          {{ line.total_value }}
        </td>
        <td class="text-center p-4">
          <div class="small">
            {{ line.processed_date }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
  @import '../../../../assets/stylesheets/partials/_variables.scss';

  table.processed-order-lines {
    th {
      font-size: 1.4rem;
      font-weight: 600;
      color: lighten(black, 30%);
      background-color: lighten(black, 92%);
    }

    td {
      border-bottom: 1px solid lighten(black, 95%);
    }

    tr:hover {
      td {
        background-color: lighten(black, 96%);
      }
    }

    tbody {
      th {
        font-size: 1.5rem;
        font-weight: 500;
        background-color: white;
        border-bottom: 2px solid lighten(black, 92%);
      }
    }

    .product-description {
      min-width: 200px;
    }

    .order-code {
      border-right: 1px solid lighten(black, 92%);
    }

    .font-medium {
      font-weight: 500;
    }

    .font-semibold {
      font-weight: 600;
    }

    .color-fun-green {
      color: $fun-green;
    }

    .color-milano-red {
      color: $milano-red;
    }

    .color-light-grey {
      color: lighten(black, 60%);
    }

    .font-size-15 {
      font-size: 1.5rem;
    }

    .font-size-14 {
      font-size: 1.4rem;
    }
    .font-size-13 {
      font-size: 1.3rem;
    }

    .font-size-12 {
      font-size: 1.2rem;
    }

    .leading-7 {
      line-height: 1.75rem;
    }

    .loading-icon {
      min-height: 200px;
    }
  }
</style>