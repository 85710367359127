import Swiper, { Autoplay, Navigation, Pagination, Scrollbar, Thumbs } from "swiper"

export function initHeaderInfoSwiper() {
  const sliderSection = document.querySelector(".header-info__texts")

  if (sliderSection) {
    const swiper = new Swiper(sliderSection.querySelector(".swiper"), {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    })
  }
}

export function initHomepageSlider() {
  const sliderSection = document.querySelector(".homepage__slider")

  if (sliderSection) {
    const swiper = new Swiper(sliderSection.querySelector(".swiper"), {
      modules: [Autoplay, Pagination],
      slidesPerView: 1,
      watchOverflow: true,
      speed: 500,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: sliderSection.querySelector(".swiper-pagination"),
        type: "bullets",
        clickable: true
      },
    })
  }
}

export function initPreorderSliders() {
  const sliderSection = document.querySelector(".homepage__pre-orders")
  
  const swiperNav = sliderSection.querySelector(".product-nav")
  const bannerSlider = sliderSection.querySelector(".homepage__pre-orders-banners")
  const productSlider = sliderSection.querySelector(".homepage__pre-orders-products")

  if (sliderSection && bannerSlider) {
    const swiper = new Swiper(bannerSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      navigation: {
        nextEl: sliderSection.querySelector(".banners.swiper-button-next"),
        prevEl: sliderSection.querySelector(".banners.swiper-button-prev"),
      },
    })
  }

  if (sliderSection && productSlider) {
    const swiper = new Swiper(productSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 25
        }
      },
      navigation: {
        nextEl: swiperNav.querySelector(".swiper-button-next"),
        prevEl: swiperNav.querySelector(".swiper-button-prev"),
      }
    })
  }
}

export function initNewReleasesSlider() {
  const sliderSection = document.querySelector(".homepage__new-releases")

  const swiperNav = sliderSection.querySelector(".product-nav")
  const bannerSlider = sliderSection.querySelector(".homepage__new-releases-banners")
  const productSlider = sliderSection.querySelector(".homepage__new-releases-products")

  if (sliderSection && bannerSlider) {
    const swiper = new Swiper(bannerSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      navigation: {
        nextEl: sliderSection.querySelector(".banners.swiper-button-next"),
        prevEl: sliderSection.querySelector(".banners.swiper-button-prev"),
      },
    })
  }

  if (sliderSection && productSlider) {
    const swiper = new Swiper(productSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 25
        }
      },
      navigation: {
        nextEl: swiperNav.querySelector(".swiper-button-next"),
        prevEl: swiperNav.querySelector(".swiper-button-prev"),
      }
    })
  }
}


export function initRelatedProductsSlider() {
  const sliders = document.querySelectorAll(".product-page__related")

  if (!sliders)
    return

  for(let slider of sliders) {
    new Swiper(slider.querySelector(".swiper-container"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 25,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        576: {
          slidesPerView: 1,
        }
      },
      navigation: {
        nextEl: slider.querySelector(".swiper-button-next"),
        prevEl: slider.querySelector(".swiper-button-prev"),
      }
    })
  }
}


export function initUpdatedPricesSlider() {
  const sliderSection = document.querySelector(".homepage__updated-prices")
  const productSlider = sliderSection.querySelector(".homepage__updated-prices-products")

  if (sliderSection && productSlider) {
    const swiper = new Swiper(productSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1.3,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      breakpoints: {
        1600: {
          slidesPerView: 1.3,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        320: {
          slidesPerView: 1,
        }

      },
      navigation: {
        nextEl: sliderSection.querySelector(".swiper-button-next"),
        prevEl: sliderSection.querySelector(".swiper-button-prev"),
      }
    })
  }
}

export function initHighlightsSlider() {
  const sliderSection = document.querySelector(".homepage__highlights")

  const swiperNav = sliderSection.querySelector(".product-nav")
  const bannerSlider = sliderSection.querySelector(".homepage__highlights-banners")
  const productSlider = sliderSection.querySelector(".homepage__highlights-products")

  if (sliderSection && bannerSlider) {
    const swiper = new Swiper(bannerSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      navigation: {
        nextEl: sliderSection.querySelector(".banners.swiper-button-next"),
        prevEl: sliderSection.querySelector(".banners.swiper-button-prev"),
      },
    })
  }

  if (sliderSection && productSlider) {
    const swiper = new Swiper(productSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 25
        }
      },
      navigation: {
        nextEl: swiperNav.querySelector(".swiper-button-next"),
        prevEl: swiperNav.querySelector(".swiper-button-prev"),
      }
    })
  }
}

export function initBrandsSlider() {
  const sliderSection = document.querySelector(".homepage__our-brands")
  if (sliderSection) {
    const swiper = new Swiper(sliderSection.querySelector(".swiper"), {
      modules: [Scrollbar],
      slidesPerView: 2,
      breakpoints: {
        576: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 7,
        }
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
        hide: false
      }
    })
  }
}

export function initArticlePageSlider() {
  const sliderSection = document.querySelector(".article-page__related-products")
  const productSlider = sliderSection.querySelector(".article-page__related-slides")
  const swiperNav = sliderSection.querySelector(".product-nav")

  if (sliderSection && productSlider) {
    const swiper = new Swiper(productSlider.querySelector(".swiper"), {
      modules: [Navigation],
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 5,
      watchOverflow: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 25
        }
      },
      navigation: {
        nextEl: swiperNav.querySelector(".swiper-button-next"),
        prevEl: swiperNav.querySelector(".swiper-button-prev"),
      }
    })
  }
}