<script setup>
import { storeToRefs } from 'pinia'
import { useStore } from '../../stores/orderShipment.js'
import OrderShipmentStep from './OrderShipmentStep.vue'

// Store
const store = useStore()
const { steps } = storeToRefs(store)

</script>

<template>
  <div class="shipment-steps d-flex flex-column">
    <OrderShipmentStep
      v-for="(step, index) in steps"
      :key="step.code"
      :index="index"
      :step="step"
    />
  </div>
</template>

<style lang="scss">
  .shipment-steps {
    .step:first-child {
      .step-number:before {
        display: none;
      }
    }
  }
</style>