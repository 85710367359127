<script setup>
import { i18n } from '../../lib/i18n'
import { defineProps, computed } from 'vue'
import { useStore } from '../../stores/orderShipment.js'

// Store
const store = useStore()

const I18n = i18n

// Props
const props = defineProps({
  orderShipment: {
    type: Object,
    required: true
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  review: {
    type: Boolean,
    default: false
  }
})

// Computed
const readOnly = computed(() => {
  return props.readOnly || props.review
})

// Methods
const destroyLine = (line_to_destroy) => {
  const { orderShipment } = props
  const { b2b_order_shipment_lines } = orderShipment
  const index = b2b_order_shipment_lines.findIndex(line => line.id === line_to_destroy.id)
  b2b_order_shipment_lines.splice(index, 1)
}
</script>

<template>
  <table class="table shipment-lines-table">
    <thead>
      <tr>
        <th class="text-start p-4">
          {{ I18n.t("order_shipments.shipment_lines_table.product") }}
        </th>
        <th class="text-center p-4">
          {{ I18n.t("order_shipments.shipment_lines_table.codes") }}
        </th>
        <th class="text-center p-4">
          {{ I18n.t("order_shipments.shipment_lines_table.attributes") }}
        </th>
        <th class="text-center p-4">
          <span v-html="I18n.t('order_shipments.shipment_lines_table.quantity_available_for_shipping')" />
        </th><th class="text-center p-4">
          {{ I18n.t("order_shipments.shipment_lines_table.quantity_to_ship") }}
        </th>
        <th
          v-if="review"
          class="text-end p-4 pe-5"
        >
          {{ I18n.t("order_shipments.shipment_lines_table.price") }}
        </th>
        <th
          v-if="!readOnly"
          class="text-center p-4"
        />
      </tr>
    </thead>
    <tbody>
      <tr v-if="store.orderShipmentLoading">
        <td
          colspan="7"
          class="text-center"
        >
          <vue-loaders-ball-beat
            color="#666"
            scale="1"
          />
        </td>
      </tr>
      <tr
        v-for="line in orderShipment.b2b_order_shipment_lines"
        v-else
        :key="line.id"
      >
        <td class="p-4 product-description">
          <h4 class="font-medium font-size-15 leading-7">
            {{ line.data.item_description }}
          </h4>

          <div class="font-size-12 color-light-grey">
            {{ line.data.item_code }}
          </div>
        </td>
        <td class="text-center p-4 font-size-13">
          {{ line.data.barcode }}
        </td>
        <td class="text-center">
          <!-- TODO-->
        </td>
        <td class="text-center p-4 font-semibold">
          {{ line.initial_quantity }}
        </td>
        <td class="text-center p-4 font-semibold color-fun-green">
          <div v-if="readOnly">
            {{ line.selected_quantity }}
          </div>
          <div v-else>
            <input
              v-model="line.selected_quantity"
              type="number"
              class="form-control text-center font-semibold"
              min="1"
              :max="line.initial_quantity"
              @focus="$event.target.select()"
            >
          </div>
        </td>
        <td
          v-if="review"
          class="text-end color-milano-red font-medium p-4 pe-5"
        >
          {{ $filters.currency(line.price) }}
        </td>
        <td
          v-if="!readOnly"
          class="text-center p-4"
        >
          <a
            href=" #"
            @click.prevent="destroyLine(line)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-trash"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              />
              <line
                x1="4"
                y1="7"
                x2="20"
                y2="7"
              />
              <line
                x1="10"
                y1="11"
                x2="10"
                y2="17"
              />
              <line
                x1="14"
                y1="11"
                x2="14"
                y2="17"
              />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/partials/_variables.scss';

table.shipment-lines-table {
  th {
    font-size: 1.4rem;
    font-weight: 600;
    color: lighten(black, 30%);
    background-color: lighten(black, 92%);
  }

  td {
    border-bottom: 1px solid lighten(black, 95%);
  }

  tr:hover {
    td {
      background-color: lighten(black, 96%);
    }
  }

  tbody {
    th {
      font-size: 1.5rem;
      font-weight: 500;
      background-color: white;
      border-bottom: 2px solid lighten(black, 92%);
    }
  }

  .product-description {
    min-width: 200px;
  }

  .order-code {
    border-right: 1px solid lighten(black, 92%);
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .color-fun-green {
    color: $fun-green;
  }

  .color-milano-red {
    color: $milano-red;
  }

  .color-light-grey {
    color: lighten(black, 60%);
  }

  .font-size-15 {
    font-size: 1.5rem;
  }

  .font-size-14 {
    font-size: 1.4rem;
  }

  .font-size-13 {
    font-size: 1.3rem;
  }

  .font-size-12 {
    font-size: 1.2rem;
  }

  .leading-7 {
    line-height: 1.75rem;
  }

  .loading-icon {
    min-height: 200px;
  }
}
</style>