<script setup>
import { i18n } from '../../../lib/i18n'
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../../../stores/orderShipment.js'
import ShipmentLinesTable from '../ShipmentLinesTable.vue'
import OrderShipmentTotals from '../OrderShipmentTotals.vue'

const I18n = i18n

// Store
const store = useStore()
const { billingAddress, shippingAddress, shippingMethod, paymentMethod } = storeToRefs(store)

onMounted(() => {
  store.loadThirdPartyShippingCustomers()
})
</script>

<template>
  <div class="row">
    <div class="col-12 pb-5">
      <p>{{ I18n.t("order_shipments.steps.step6.subheading") }}</p>
      <ShipmentLinesTable
        :order-shipment="store.orderShipment"
        :review="true"
        class="mt-4"
      />
      <div class="row">
        <div class="col-12 col-lg-9 mt-5 px-4">
          <div class="row">
            <div class="col-12 col-lg-6 brief-info pe-4">
              <h3 class="pb-3">
                {{ I18n.t("order_shipments.steps.step6.labels.cargo_information") }}
              </h3>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.order_reference") }}</label>
              <p>{{ store.orderShipment.shipment_reference }}</p>

              <div v-if="store.orderShipment.operating_instructions">
                <label>{{ I18n.t("order_shipments.steps.step6.labels.operating_instructions") }}</label>
                <p>{{ store.orderShipment.operating_instructions }}</p>
              </div>

              <h3 class="pb-3 pt-4">
                {{ I18n.t("order_shipments.steps.step6.labels.shipping_information") }}
              </h3>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.shipping_address") }}</label>
              <p v-if="store.thirdPartyShippingSelected && store.selectedThirdPartyShippingCustome">
                {{ store.selectedThirdPartyShippingCustomer.title }}
              </p>
              <p>{{ shippingAddress.description }}</p>
              <p>
                {{ shippingAddress.title }}
              </p>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.shipping_method") }}</label>
              <p>{{ shippingMethod.shipping_method_description }}</p>
            </div>
            <div class="col-12 col-lg-6 brief-info ps-4">
              <h3 class="pb-3">
                {{ I18n.t("order_shipments.steps.step6.labels.pricing_information") }}
              </h3>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.pricing_address") }}</label>
              <p>{{ billingAddress.description }}</p>
              <p>
                {{ billingAddress.title }}
              </p>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.pricing_method") }}</label>
              <p v-if="store.orderShipment.invoice_per_order_code">
                {{ I18n.t("order_shipments.steps.step3.labels.pricing_method_2") }}
              </p>
              <p v-else>
                {{ I18n.t("order_shipments.steps.step3.labels.pricing_method_1") }}
              </p>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.invoice_text") }}</label>
              <p>{{ store.orderShipment.invoice_text }}</p>

              <h3 class="pb-3 pt-4">
                {{ I18n.t("order_shipments.steps.step6.labels.payment_information") }}
              </h3>

              <label>{{ I18n.t("order_shipments.steps.step6.labels.payment_method") }}</label>
              <p>{{ paymentMethod.title }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 ps-4">
          <OrderShipmentTotals />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  h3 {
    border-bottom: 1px solid lighten(black, 92%);
  }

  .brief-info {
    label {
      font-size: 1.4rem;
      padding-top: 8px;
      padding-bottom: 10px;
    }


    p {
      font-size: 1.5rem;
      color: lighten(black, 50%);
      margin-bottom: 5px;
    }
  }
</style>
