import { i18n } from '../lib/i18n'
import { defineStore } from 'pinia'
import { getOrderShipment, getAddresses, getThirdPartyShippingCustomers, getShippingMethods, getPaymentMethods, updateOrderShipment } from '../api/api'

export const useStore = defineStore('orderShipment', {
  state: () => {
    return {
      orderShipment: {},
      orderShipmentLoading: true,
      addresses: [],
      thirdPartyShippingCustomers: [],
      shippingMethods: null,
      shippingMethodsLoading: true,
      paymentMethods: [],
      selectedShippingInformationTab: {},
      shippingInformationTabs: [
        {
          code: "my-address",
          title: i18n.t("order_shipments.shipping_information_tabs.my_address.title")
        },
        {
          code: "third-party-address",
          title: i18n.t("order_shipments.shipping_information_tabs.third_party_address.title"),
          tooltipDescription: i18n.t("order_shipments.shipping_information_tabs.third_party_address.tooltip")
        }
      ],
      step: 1,
      steps: [
        {
          code: "set_quantities",
          title: i18n.t("order_shipments.steps.step1.title"),
          heading: i18n.t("order_shipments.steps.step1.heading"),
          nextButton: i18n.t("order_shipments.steps.step1.nextButton")
        },
        {
          code: "set_shipment_options",
          title: i18n.t("order_shipments.steps.step2.title"),
          heading: i18n.t("order_shipments.steps.step2.heading"),
          previousButton: i18n.t("order_shipments.steps.step2.previousButton"),
          nextButton: i18n.t("order_shipments.steps.step2.nextButton")
        },
        {
          code: "set_pricing_options",
          title: i18n.t("order_shipments.steps.step3.title"),
          heading: i18n.t("order_shipments.steps.step3.heading"),
          previousButton: i18n.t("order_shipments.steps.step3.previousButton"),
          nextButton: i18n.t("order_shipments.steps.step3.nextButton")
        },
        {
          code: "set_shipping_information",
          title: i18n.t("order_shipments.steps.step4.title"),
          heading: i18n.t("order_shipments.steps.step4.heading"),
          previousButton: i18n.t("order_shipments.steps.step4.previousButton"),
          nextButton: i18n.t("order_shipments.steps.step4.nextButton")
        },
        {
          code: "set_payment_method",
          title: i18n.t("order_shipments.steps.step5.title"),
          heading: i18n.t("order_shipments.steps.step5.heading"),
          previousButton: i18n.t("order_shipments.steps.step5.previousButton"),
          nextButton: i18n.t("order_shipments.steps.step5.nextButton")
        },
        {
          code: "review_summary",
          title: i18n.t("order_shipments.steps.step6.title"),
          heading: i18n.t("order_shipments.steps.step6.heading"),
          previousButton: i18n.t("order_shipments.steps.step6.previousButton"),
          nextButton: i18n.t("order_shipments.steps.step6.nextButton")
        },
        {
          code: "place",
          title: i18n.t("order_shipments.steps.step7.title"),
          heading: i18n.t("order_shipments.steps.step7.heading"),
        }
      ]
    }
  },
  getters: {
    currentStep(state) {
      return state.steps[state.step - 1]
    },
    selectedThirdPartyShippingCustomer(state) {
      return state.thirdPartyShippingCustomers.find(customer => customer.id === state.orderShipment.third_party_shipping_b2b_customer_id)
    },
    thirdPartyCustomerAddresses(state) {
      if (!this.selectedThirdPartyShippingCustomer)
        return []

      return this.selectedThirdPartyShippingCustomer.addresses
    },
    selectedThirdPartyShippingAddress(state) {
      if (!state.orderShipment.third_party_shipping_b2b_address_id)
        return {}

      return this.thirdPartyCustomerAddresses.find(address => address.id === state.orderShipment.third_party_shipping_b2b_address_id) || {}
    },
    thirdPartyShippingSelected() {
      return this.orderShipment.third_party_shipping_selected
    },
    billingAddress() {
      if (!this.orderShipment.b2b_billing_address_id)
        return {}

      return this.addresses.find(address => address.id === this.orderShipment.b2b_billing_address_id) || {}
    },
    shippingAddress() {
      if (this.thirdPartyShippingSelected) {
        return this.selectedThirdPartyShippingAddress
      }
      else {
        let addressId = this.orderShipment.b2b_shipping_address_id

        if (addressId == null)
          return {}

        return this.addresses.find(address => address.id === addressId) || {}
      }
    },
    shippingMethod() {
      return this.orderShipment?.shipping_method || {}
    },
    paymentMethod() {
      if (!this.orderShipment.payment_method_id)
        return {}

      return this.paymentMethods.find(method => method.id === this.orderShipment.payment_method_id) || {}
    },
    orderShipmentPlaced(){
      return this.orderShipment.state == "placed"
    }
  },
  actions: {
    setCurrentStep(value) {
      if (value) {
        this.step = value
      } else {
        this.step = this.orderShipment.current_step
      }
    },
    setShippingInformationTab(value) {
      if (value == "third-party-address") {
        this.orderShipment.third_party_shipping_selected = true
      }
      else {
        this.orderShipment.third_party_shipping_selected = false
      }

      // Set also seleced tab
      this.selectedShippingInformationTab = this.shippingInformationTabs.filter(tab => tab.code == value)[0]
    },
    preselectShippingInformationTab() {
      const value = this.orderShipment.third_party_shipping_selected ? "third-party-address" : "my-address"
      this.setShippingInformationTab(value)
    },
    async loadOrderShipment(id) {
      this.orderShipmentLoading = true

      let orderId = id || this.orderShipment.id

      // TODO: Handle errors
      const response = await getOrderShipment(orderId)
      this.orderShipment = response.data.b2b_order_shipment

      this.orderShipmentLoading = false

      this.preselectShippingInformationTab()
      this.setCurrentStep()
    },
    async updateOrderShipment({ step }) {
      try {
        const response = await updateOrderShipment({
          b2b_order_shipment: this.orderShipment,
          step
        })
      } catch (error) {
        console.log(error)
      }
    },
    async loadAddresses() {
      // TODO: Handle errors
      const response = await getAddresses()
      this.addresses = response.data.b2b_addresses
    },
    async loadThirdPartyShippingCustomers() {
      if (this.thirdPartyShippingCustomers.length > 0)
        return

      const response = await getThirdPartyShippingCustomers()
      this.thirdPartyShippingCustomers = response.data.b2b_customers
    },
    async loadShippingMethods() {
      const b2bCustomerId = this.orderShipment.third_party_shipping_b2b_customer_id
      const b2bAddressId = this.orderShipment.b2b_shipping_address_id || this.orderShipment.third_party_shipping_b2b_address_id

      if (b2bAddressId == null)
        return

      const response = await getShippingMethods(
        b2bCustomerId,
        b2bAddressId
      )
      this.shippingMethods = response.data.transport_options
    },
    async loadPaymentMethods() {
      try {
        this.shippingMethodsLoading = true
        const response = await getPaymentMethods()
        this.paymentMethods = response.data.payment_methods
      } catch (error) {
        console.log(error)
      }

      this.shippingMethodsLoading = false
    }
  },
  async updateOrderShipmentState() {
    const step = this.step

    try {
      const response = await updateOrderShipment({
        step
      })
      this.orderShipment = response.data.b2b_order_shipment
    } catch (error) {
      console.log(error)
    }
  }
})
