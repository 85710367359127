<script>
import { toRefs, reactive } from 'vue'
import { useOffsetPagination } from '@vueuse/core'

export default {
  name: "ProcessedOrderLinesPaginate",
  props: {
    page: {
      required: true,
      type: Number
    },
    pageSize: {
      default: 100,
      type: Number
    },
    totalCount: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    const { pageSize: pageSize } = toRefs(props)

    // Fetch data on load of page, to display table rows
    fetchData({
      currentPage: props.page,
      currentPageSize: props.pageSize
    })

    function fetchData({ currentPage, currentPageSize }) {
      emit('page-change', { currentPage, currentPageSize })
    }

    const {
      currentPage,
      currentPageSize,
      pageCount,
      isFirstPage,
      isLastPage,
      prev,
      next
    } = useOffsetPagination({
      total: props.totalCount,
      page: props.page,
      pageSize,
      onPageChange: fetchData,
      onPageSizeChange: fetchData
    })

    return {
      currentPage,
      currentPageSize,
      pageCount,
      isFirstPage,
      isLastPage,
      prev,
      next
    }
  },
  data() {
    return {
      windowSize: 11
    }
  },
  computed: {
    showStartEllipsis() {
      return this.windowRange[0] != this.pagesRange[0]
    },
    showEndEllipsis() {
      return this.windowRange.slice(-1) < this.pageCount
    },
    halfWindow() {
      return Math.floor(this.windowSize / 2)
    },
    windowRange() {
      const range = this.pagesRange.slice(0, this.windowSize)

      if (this.currentPage - this.halfWindow + this.windowSize > this.pageCount) {
        return this.pagesRange.slice(this.windowSize * -1)
      }
      else if (this.currentPage + this.halfWindow > range.slice(-1)) {
        const startFrom = this.currentPage - this.halfWindow - 1
        const endTo = this.currentPage + this.halfWindow

        return this.pagesRange.slice(startFrom, endTo)
      } else {
        return range
      }

    },
    pagesRange() {
      return Array.from({ length: this.pageCount }, (_, i) => i + 1)
    }
  }
}
</script>

<template>
  <div>
    <nav class="pagination d-flex">
      <button
        :disabled="isFirstPage"
        class="prev me-2"
        @click="prev"
      >
        ‹
      </button>

      <div
        v-if="showStartEllipsis"
        class="px-2 ellipsis"
      >
        ...
      </div>

      <button
        v-for="item in windowRange"
        :key="item"
        :disabled="currentPage === item"
        class="mx-1"
        :class="{ selected:currentPage === item }"
        @click="currentPage = item"
      >
        {{ item }}
      </button>

      <div
        v-if="showEndEllipsis"
        class="px-2 ellipsis"
      >
        ...
      </div>

      <button
        :disabled="isLastPage"
        class="next ms-2"
        @click="next"
      >
        ›
      </button>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../../assets/stylesheets/partials/_variables.scss';

  .pagination {
    button, div {
      font-size: 1.4rem;
      border: none;
      background: none;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 6rem;
      line-height: 3.5rem;
      text-align: center;
      margin: 0 0.2rem;
      font-weight: 500;
    }

    button {
      &:hover {
        background-color: lighten(black, 95%);
      }

      &:active {
        background-color: lighten(black, 90%);
      }

      &.selected {
        background-color: $mine-shaft;
        color: white;
        cursor: default;
      }
    }

    .ellipsis {
      width: 2rem;
      text-align: center;
      color: lighten(black, 60%);
    }
  }
</style>