<script setup>
import { i18n } from "../../../lib/i18n"
import { reactive, defineProps, defineEmits, onMounted, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import Fuse from 'fuse.js'

// Props
const I18n = i18n
const props = defineProps({
  lines: {
    type: Object,
    default: []
  }
})

// Data
const state = reactive({ query: '' })

// Watchers
watch(() => props.lines, (newLines, oldLines) => {
  if (newLines.length > 0) {
    search()
  }
})

// Emits
const emit = defineEmits(['on-result'])

// Methods
const search = useDebounceFn(() => {
  let result
  const fuse = new Fuse(props.lines, searchOptions)

  if (state.query.length > 0) {
    result = fuse.search(state.query)
  } else {
    result = props.lines.map((item) => ({ item, matches: [], score: 1 }))
  }

  emit('on-result', result)
}, 200)

const searchOptions = {
  includeScore: true,
  threshold: 0.5,
  distance: 200,
  includeMatches: true,
  //ignoreLocation: true,
  keys: ['item_code', 'item_description', 'barcode']
}
</script>

<template>
  <div
    ref="target"
    class="search d-inline-block d-flex align-items-center pb-2"
  >
    <i class="icon icon-nv-search" />
    <input
      v-model="state.query"
      type="text"
      class="form-control"
      :placeholder="I18n.t('processed_order_lines_table.search.placeholder')"
      @keyup="search"
    >
  </div>
</template>

<style lang="scss" scoped>
  @import '../../../../assets/stylesheets/partials/_mixins.scss';

  .search {
    position: relative;

    .icon {
      position: absolute;
      top: 8px;
      left: 10px;
      color: black;
      font-size: 2rem;

      &:before {
        content: "\FDD3"
      }
    }

    input {
      text-indent: 22px;
      font-size: 1.4rem;
      min-width: 380px;

      @include placeholder {
        font-size: 1.4rem;
      }
    }
  }
</style>
