<template>
  <li
    class="nav-item px-2"
    :class="{ active: active }"
  >
    <button
      class="nav-link px-5 py-3"
      @click="$emit('select', tab.code)"
    >
      {{ tab.title }}

      <span
        v-if="tooltipExists"
        class="ps-2"
      >
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          class="icon-information"
        >
          <path
            d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
            fill-rule="nonzero"
          />
        </svg>
      </span>
    </button>
  </li>
</template>

<script>
import tippy from "tippy.js"

export default {
  name: "ShipmentTabNav",
  props: {
    tab: {
      required: true,
      type: Object
    },
    selected: {
      required: true,
      type: Object
    }
  },
  computed: {
    active() {
      return this.tab.code == this.selected.code
    },
    tooltipExists() {
      return this.tab.tooltipDescription && this.tab.tooltipDescription.length > 0
    }
  },
  mounted() {
    if (this.tooltipExists) {
      tippy(this.$el.querySelector("svg"), {
        content: this.tab.tooltipDescription
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/stylesheets/partials/_variables.scss';

  li:first-child {
    padding-left: 0 !important;
  }

  button {
    font-size: 1.4rem;
    font-weight: 500;
    border: 2px solid transparent;

    &:hover {
      background-color: lighten($black, 90%);
      border-bottom: 2px solid $milano-red;
    }
  }

  .icon-information {
    outline: none;
    width: 18px;
    color: lighten(black, 76%);
    fill: currentColor;

    &:hover {
      color: lighten(black, 40%);
    }
  }

  .active {
    button {
      background-color: $milano-red;
      color: white;
      border-bottom: 2px solid darken($milano-red, 10%);
    }

    .icon-information:hover {
      color: white;
    }

  }
</style>
