<script>
import { i18n } from "../../lib/i18n"
import Paginate from './processed_order_lines/Paginate.vue'
import Per from './processed_order_lines/Per.vue'
import Search from './processed_order_lines/Search.vue'
import NewShipmentButton from './NewShipmentButton.vue'
import Table from './processed_order_lines/Table.vue'

export default {
  name: "TabPane",
  components: {
    Table,
    Paginate,
    Per,
    Search,
    NewShipmentButton
  },
  props: {
    tab: {
      required: true,
      type: Object
    },
    selected: {
      required: true,
      type: Object
    },
    loading: {
      default: true,
      type: Boolean
    },
    lines: {
      default: [],
      type: Object
    }
  },
  data() {
    return {
      I18n: i18n,
      page: 1,
      pageSize: 50,
      start: 1,
      end: 1,
      selectedLines: {},
      searchResults: [],
      paginatedSearchResults: []
    }
  },
  computed: {
    active() {
      return this.tab.code == this.selected.code
    },
    processedOrderLines() {
      return this.lines.filter(line => line.availability_type == this.tab.code)
    }
  },
  methods: {
    setPaginatedSearchResults({ currentPage, currentPageSize }) {
      this.page = currentPage
      this.pageSize = currentPageSize

      this.start = (currentPage - 1) * currentPageSize
      this.end = this.start + currentPageSize

      this.paginatedSearchResults = this.searchResults.slice(this.start, this.end)
    },
    setPageSize(value) {
      this.pageSize = value
    },
    setSearchResults(value) {
      this.searchResults = value
      this.setPaginatedSearchResults({ currentPage: this.page, currentPageSize: this.pageSize })
    },
    setSelectedLines(value) {
      this.selectedLines = value
    }
  }
}
</script>

<template>
  <div
    class="tab-pane"
    :class="{ active: active }"
  >
    <slot>
      <div class="d-flex justify-content-end py-3">
        <NewShipmentButton
          v-if="tab.canCreateShipment"
          :lines="lines"
          :selected-lines="selectedLines"
        />
      </div>

      <div class="d-flex align-items-center justify-content-between py-4">
        <Search
          :lines="processedOrderLines"
          @on-result="setSearchResults"
        />

        <div class="results-count">
          {{ I18n.t("processed_order_lines_table.tab_pane.viewing") }}
          <strong>{{ start + 1 }}</strong> - <strong>{{ end }}</strong>
          {{ I18n.t("processed_order_lines_table.tab_pane.of") }}
          <strong>{{ searchResults.length }}</strong>
          {{ I18n.t("processed_order_lines_table.tab_pane.records") }}
        </div>

        <Per @select="setPageSize" />

        <Paginate
          v-if="searchResults.length > 0"
          :page="page"
          :page-size="pageSize"
          :total-count="searchResults.length"
          @page-change="setPaginatedSearchResults"
        />
      </div>

      <Table
        :tab="tab"
        :loading="loading"
        :lines="paginatedSearchResults"
        @select="setSelectedLines"
      />

      <div class="d-flex justify-content-end py-4">
        <Paginate
          v-if="searchResults.length > 0"
          :page="page"
          :page-size="pageSize"
          :total-count="searchResults.length"
          @page-change="setPaginatedSearchResults"
        />
      </div>

      <div class="d-flex justify-content-end py-3">
        <NewShipmentButton
          v-if="tab.canCreateShipment"
          :lines="lines"
          :selected-lines="selectedLines"
        />
      </div>
    </slot>
  </div>
</template>

<style lang="scss">
  .results-count {
    font-size: 1.4rem;

    strong {
      font-weight: 500 !important;
    }
  }
</style>
