<script setup>
import { i18n } from '../../../lib/i18n'
import { useStore } from '../../../stores/orderShipment.js'

const I18n = i18n

// Store
const store = useStore()
</script>

<template>
  <div class="row">
    <div class="col-10 col-lg-6 mx-auto text-center cargo-place">
      <img
        src="../../../images/cargo-place-bg.png"
        width="320"
        height="262"
      >
      <h1 class="my-5">
        {{ I18n.t("order_shipments.steps.step7.subheading", { code: store.orderShipment.code || "" }) }}
      </h1>
      <p>
        {{ I18n.t("order_shipments.steps.step7.text1") }}
      </p>
      <p>
        {{ I18n.t("order_shipments.steps.step7.text2") }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  h1 {
    font-size: 4rem;
  }

  .cargo-place {

  }
</style>
