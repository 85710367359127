<script setup>
import { i18n } from '../../../lib/i18n'
import { onMounted } from 'vue'
import { useStore } from '../../../stores/orderShipment.js'
import Tooltip from '../../Tooltip.vue'

const I18n = i18n

// Store
const store = useStore()

// Mounted
onMounted(() => {

})
</script>

<template>
  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="py-4">
        <label class="pb-2">{{ I18n.t("order_shipments.steps.step5.labels.payment_method") }}</label>
        <div class="checkbox-group">
          <div
            v-for="paymentMethod in store.paymentMethods"
            :key="paymentMethod.id"
            class="form-check checkbox-wrapper p-0 mb-2"
          >
            <label class="form-check-label p-3">
              <input
                v-model="store.orderShipment.payment_method_id"
                type="radio"
                class="form-check-input mx-3"
                name="payment-method-option"
                :value="paymentMethod.id"
              >
              {{ paymentMethod.title }}

              <Tooltip
                :message="paymentMethod.description"
                class="ps-3"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
