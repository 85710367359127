<script setup>
import { useStore } from '../../../stores/orderShipment.js'
import { i18n } from '../../../lib/i18n'
import ShipmentLinesTable from '../ShipmentLinesTable.vue'

// Props
const I18n = i18n

// Store
const store = useStore()
</script>

<template>
  <div class="row">
    <div class="col-12">
      <p>{{ I18n.t("order_shipments.steps.step1.subheading") }}</p>
      <ShipmentLinesTable
        :order-shipment="store.orderShipment"
        class="mt-4 mb-5"
      />
    </div>
  </div>
</template>
