<script setup>
import { i18n } from '../../../lib/i18n'
import { computed, onMounted, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useStore } from '../../../stores/orderShipment.js'
import Tooltip from '../../Tooltip.vue'

const I18n = i18n

// Store
const store = useStore()
</script>

<template>
  <div class="row">
    <div class="col-8">
      <div class="py-4">
        <label class="pb-2">{{ I18n.t("order_shipments.steps.step3.labels.pricing_address") }}</label>
        <Multiselect
          v-model="store.orderShipment.b2b_billing_address_id"
          :options="store.addresses"
          track-by="description"
          label="description"
          value-prop="id"
          placeholder="Select address..."
          :searchable="true"
        />
      </div>
      <div class="py-4">
        <h4 class="pb-3">
          {{ I18n.t("order_shipments.steps.step3.labels.pricing_method") }}
        </h4>

        <div class="form-check pb-2">
          <input
            id="pricing-method-1"
            v-model="store.orderShipment.invoice_per_order_code"
            type="radio"
            class="form-check-input"
            name="pricing-method"
            value="false"
          >
          <label
            class="form-check-label"
            for="pricing-method-1"
          >
            {{ I18n.t("order_shipments.steps.step3.labels.pricing_method_1") }}
          </label>
        </div>

        <div class="form-check">
          <input
            id="pricing-method-2"
            v-model="store.orderShipment.invoice_per_order_code"
            type="radio"
            class="form-check-input"
            name="pricing-method"
            value="true"
          >
          <label
            class="form-check-label"
            for="pricing-method-2"
          >
            {{ I18n.t("order_shipments.steps.step3.labels.pricing_method_2") }}

            <Tooltip
              :message="I18n.t('order_shipments.steps.step3.tooltips.pricing_method')"
            />
          </label>
        </div>
      </div>
      <div class="py-4">
        <label class="pb-2">{{ I18n.t("order_shipments.steps.step3.labels.invoice_text") }}</label>
        <textarea
          v-model="store.orderShipment.invoice_text"
          rows="6"
          class="form-control"
        />
        <div class="form-text">
          {{ I18n.t("order_shipments.steps.step3.help.invoice_text") }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
