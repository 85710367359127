import Swal from 'sweetalert2'
import { i18n } from '../lib/i18n'

// Functions used in Checkout - Step 2
export function bindOrderEvents() {
  if (document.querySelector(".checkout-2")) {
    const billing = document.querySelector(".billing-address")
    const shipping = document.querySelector(".shipping-address")
    const shippingContainer = shipping.querySelector(".container")
    const shippingAddressCheck = shipping.querySelector("#use_as_shipping_info")
    const invoiceContainer = billing.querySelector(".invoice-fields")

    // Disables the shipping inputs if the shipping address is the same as the billing address
    const disableShippingFields = () => {
      if (shippingAddressCheck.checked) {

        shipping.classList.add("readonly")

        shipping.querySelectorAll("input[type='text'], select").forEach(input => {
          input.setAttribute("readonly", "readonly")
        })
      } else {
        shipping.classList.remove("readonly")

        shipping.querySelectorAll("input[type='text']").forEach(input => {
          input.removeAttribute("readonly")
        })
      }
    }

    // Toggles the invoice fields when the invoice radio button in checked
    const toggleInvoiceFields = () => {
      if (document.querySelector("input[name='order[is_invoice]']:checked").value === "true") {
        invoiceContainer.classList.remove("d-none")
      } else {
        invoiceContainer.classList.add("d-none")
      }
    }

    billing.querySelectorAll("input[type=text], input[type=tel], input[type=email], select").forEach(field => {
      field.addEventListener("change", function () {

        if (shippingAddressCheck.checked) {
          let target = document.querySelector(`#${this.getAttribute("id").replace("billing", "shipping")}`)
          target.value = this.value
        }
      })
    })

    toggleInvoiceFields()

    document.querySelectorAll(".receipt-or-invoice input[type='radio']").forEach(radio => {

      radio.addEventListener("change", function () {
        toggleInvoiceFields()
      })
    })

    disableShippingFields()

    shippingAddressCheck.addEventListener("change", function () {
      disableShippingFields()
    })
  }
}

export function giftTextOpacity() {
  const giftCheckbox = document.querySelector(".gift-checkbox")

  if (giftCheckbox !== null) {
    const giftTextArea = document.querySelector(".gift-section textarea")

    giftCheckbox.querySelector("label").addEventListener("click", (e) => {
      if (e.target["type"] === "checkbox") return

      giftTextArea.classList.toggle("gift-text-opacity")
      giftTextArea.toggleAttribute("readOnly")

      if (giftTextArea.classList.contains("gift-text-opacity")) {
        giftTextArea.value = ""
      }
    })
  }
}

export function removeFooterMarginTop() {
  let footer = document.querySelector(".footer")
  let checkoutPlace = document.querySelector(".checkout-4")

  if (checkoutPlace) {
    document.querySelector(".footer").style.marginTop = 0
  }
}

export function enableSweetAlertOnCheckoutButtons() {
  const buttons = document.querySelectorAll(".gy-button-checkout")

  for (let button of buttons) {
    button.addEventListener("click", (e) => {
      e.preventDefault()

      Swal.fire({
        title: i18n.t("orders.show.confirm_dialog.title"),
        text: i18n.t("orders.show.confirm_dialog.text"),
        icon: "info",
        showCancelButton: true,
        scrollbarPadding: false,
        confirmButtonColor: '#BB0907',
        cancelButtonColor: '#ccc',
        cancelButtonText: i18n.t("orders.show.confirm_dialog.cancelButtonText"),
        confirmButtonText: i18n.t("orders.show.confirm_dialog.confirmButtonText")
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = button.getAttribute("href")
        }
      })
    })
  }
}