<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../../stores/orderShipment.js'
import { i18n } from '../../lib/i18n'
import Swal from 'sweetalert2'
import Step1Pane from './steps/Step1Pane.vue'
import Step2Pane from './steps/Step2Pane.vue'
import Step3Pane from './steps/Step3Pane.vue'
import Step4Pane from './steps/Step4Pane.vue'
import Step5Pane from './steps/Step5Pane.vue'
import Step6Pane from './steps/Step6Pane.vue'
import Step7Pane from './steps/Step7Pane.vue'
import ShipmentLinesTable from './ShipmentLinesTable.vue'

const I18n = i18n

// Store
const store = useStore()
const { currentStep } = storeToRefs(store)

// Computed
const previousPageExists = computed(() => {
  return store.step > 1
})

const nextPageExists = computed(() => {
  return store.step < store.steps.length
})

// Do not show order shipment lines table in steps 1 & 7
const showOrderShipmentLinesTable = computed(() => {
  return [1, 6, 7].indexOf(store.step) == -1
})

const showButtons = computed(() => {
  return [7].indexOf(store.step) == -1
})

// Methods
const previous = () => {
  store.loadOrderShipment().then(() => {
    store.step--
  })
}

const showConfirmDialog = () => {
  Swal.fire({
    title: i18n.t("order_shipments.review_summary.confirm_dialog.title"),
    text: i18n.t("order_shipments.review_summary.confirm_dialog.text"),
    icon: "info",
    showCancelButton: true,
    scrollbarPadding: false,
    confirmButtonColor: '#BB0907',
    cancelButtonColor: '#ccc',
    cancelButtonText: i18n.t("order_shipments.review_summary.confirm_dialog.cancelButtonText"),
    confirmButtonText: i18n.t("order_shipments.review_summary.confirm_dialog.confirmButtonText")
  }).then((result) => {
    if (result.isConfirmed) {
      updateOrderShipmentAndGoToNextStep()
    }
  })
}

const updateOrderShipmentAndGoToNextStep = () => {
  store.updateOrderShipment({ step: store.step + 1 }).then(() => {
    store.step++
  })
}

const next = async () => {
  if (store.currentStep.code == "review_summary" ) {
    showConfirmDialog()
  } else {
    updateOrderShipmentAndGoToNextStep()
  }
}

const currentPageIs = (page) => {
  return page == store.step
}
</script>

<template>
  <div class="shipment-pane">
    <div class="row">
      <div class="col">
        <h1 class="mb-4">
          {{ currentStep.heading }}
        </h1>
      </div>
    </div>

    <Step1Pane v-if="currentPageIs(1)" />

    <Step2Pane v-if="currentPageIs(2)" />

    <Step3Pane v-if="currentPageIs(3)" />

    <Step4Pane v-if="currentPageIs(4)" />

    <Step5Pane v-if="currentPageIs(5)" />

    <Step6Pane v-if="currentPageIs(6)" />

    <Step7Pane v-if="currentPageIs(7)" />

    <div
      v-if="showButtons"
      class="row py-4"
    >
      <div class="col">
        <button
          v-if="previousPageExists"
          class="btn-standard"
          @click="previous"
        >
          {{ currentStep.previousButton }}
        </button>
      </div>

      <div class="col-auto">
        <button
          v-if="nextPageExists"
          class="btn-standard float-end"
          @click="next"
        >
          {{ currentStep.nextButton }}
        </button>
      </div>
    </div>

    <div
      v-if="showOrderShipmentLinesTable"
      class="row pt-5"
    >
      <div class="col-12">
        <hr class="mt-4 mb-5">
        <h2>{{ I18n.t("order_shipments.order_shipment_pane.heading_shipment_items") }}</h2>

        <ShipmentLinesTable
          :order-shipment="store.orderShipment"
          :read-only="true"
          class="my-5"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .shipment-pane {
    h1 {
      font-size: 2.2rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    label {
      font-weight: 500;
      font-size: 1.5rem;
    }

    .form-text {
      font-style: italic;
    }

    .form-check-label {
      font-weight: 400;
    }

    hr {
      width: 100%;
    }
  }
</style>
