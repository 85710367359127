import { YoutubeVideo } from "./classes/YoutubeVideo"
import { HeightEqualizer } from "./classes/HeightEqualizer"
import Rails from '@rails/ujs'

export function startRails() {
  Rails.start()
}

// Freezes the background, so the user can't interact with it.
export function freezeBackground() {
  window.freezeBackground = () => { document.querySelector("html").style.cssText += "overflow: hidden;" }

  window.freezeBackground()
}

// Unfreezes the backgroud so the user can interact with it again
export function unfreezeBackground() {
  window.unfreezeBackground = () => { document.querySelector("html").style.cssText += "overflow: auto;" }

  window.unfreezeBackground()
}

export function initYoutubeVideos() {
  new YoutubeVideo()
}

export function initHeightEqualizer() {
  new HeightEqualizer()

  const tabs = document.querySelectorAll("button[data-bs-toggle='tab']")

  for (let tab of tabs) {
    tab.addEventListener("click", function () {

      setTimeout(() => {
        new HeightEqualizer(`.equal-height${tab.getAttribute("data-bs-target")}`)
      }, 200)

    }, { once: true })
  }
}

// Makes the dropdown in swiper sections
export function addToShoppingListDropUp() {
  document.querySelectorAll("article.swiper-slide").forEach(slide => {
    slide.querySelector(".product-add-to-shopping-list")?.classList.add("dropup")
  })
}