import Swiper, { Navigation, Thumbs, Lazy } from "swiper"
import { initDriftZoom } from "../../../javascript/components/driftZoom"

export function initMainProductSlider() {

  setTimeout(() => {
    const slider = document.querySelector(".product-page__main-photo")
    const thumbs = document.querySelector(".gallery-thumbs")

    let thumbsGallery = new Swiper(thumbs, {
      modules: [Navigation, Thumbs],
      direction: 'vertical',
      spaceBetween: 8,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
    })

    new Swiper(slider, {
      modules: [Navigation, Thumbs, Lazy],
      slidesPerView: 1,
      direction: 'vertical',
      centeredSlides: true,
      thumbs: {
        swiper: thumbsGallery
      },
      lazy: true
    })

    initDriftZoom()

  }, 100)
}