<script setup>
import { i18n } from '../../lib/i18n'
import { ref, onMounted } from 'vue'
import Swal from 'sweetalert2'
import { getOrderShipments, destroyOrderShipment } from '../../api/api.js'
import OrderShipmentsTable from './OrderShipmentsTable.vue'

const orderShipments = ref([])
const loading = ref(true)

// Methods
const destroyLine = async (orderShipment) => {
  try {
    await destroyOrderShipment(orderShipment.id)
    orderShipments.value.splice(
      orderShipments.value.indexOf(orderShipment), 1
    )

    Swal.fire(
      i18n.t("order_shipments.index.destroy.success.title"),
      '',
      'success'
    )
  } catch (error) {
    console.error(error)
  }
}

const confirmDestroyLine = (orderShipment) => {
  Swal.fire({
    title: i18n.t("order_shipments.index.destroy.confirm_dialog.title"),
    text: i18n.t("order_shipments.index.destroy.confirm_dialog.text"),
    icon: "question",
    showCancelButton: true,
    scrollbarPadding: false,
    confirmButtonColor: '#BB0907',
    cancelButtonColor: '#ccc',
    cancelButtonText: i18n.t("order_shipments.index.destroy.confirm_dialog.cancelButtonText"),
    confirmButtonText: i18n.t("order_shipments.index.destroy.confirm_dialog.confirmButtonText")
  }).then((result) => {
    if (result.isConfirmed) {
      destroyLine(orderShipment)
    }
  })
}

// Mounted
onMounted(async () => {
  const response = await getOrderShipments()
  orderShipments.value = response.data.b2b_order_shipments
  loading.value = false
})
</script>

<template>
  <div class="order-shipments-history">
    <div
      v-if="loading"
      class="row"
    >
      <div
        class="col-12 p-5 d-flex justify-content-center align-items-center"
        style="min-height: 400px;"
      >
        <vue-loaders-pacman
          color="#ddd"
          scale="3"
          class=""
        />
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-12">
        <OrderShipmentsTable
          :order-shipments="orderShipments"
          @destroy-line="confirmDestroyLine"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
