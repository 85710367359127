import { createApp } from 'vue'
import { createPinia } from 'pinia'
import accounting from 'accounting'
import HistoryOrderLinesTable from './components/product/HistoryOrderLinesTable.vue'
import ShipmentTabs from './components/shipment/Tabs.vue'
import OrderShipmentWizard from './components/shipment/OrderShipmentWizard.vue'
import OrderShipmentsHistory from './components/shipment/OrderShipmentsHistory.vue'

import VueLoadersPacman from 'vue-loaders/dist/loaders/pacman'
import VueLoadersBallBeat from 'vue-loaders/dist/loaders/ball-beat'

export function initVueApp() {
  setTimeout(() => {
    const pinia = createPinia()

    const App = {
      components: {
        HistoryOrderLinesTable,
        ShipmentTabs,
        OrderShipmentWizard,
        OrderShipmentsHistory
      }
    }

    const app = createApp(App)

    // Register currency filter
    app.config.globalProperties.$filters = {
      currency(value) {
        const currency = window.current_currency

        const options = {
          symbol: currency.symbol,
          format: currency.symbol_first ? "%s%v" : "%v%s",
          decimal: currency.decimal_mark,
          thousand: currency.separator,
          precision: 2
        }

        return accounting.formatMoney(value, options)
      }
    }

    // Use any other library and mount the app
    app.use(VueLoadersPacman).
      use(VueLoadersBallBeat).
      use(pinia).
      mount('#app')

  }, 200)
}
