<script setup>
import { useStore } from '../../../stores/orderShipment.js'
import { i18n } from '../../../lib/i18n'

const I18n = i18n

// Store
const store = useStore()
</script>

<template>
  <div class="row">
    <div class="col-8">
      <p>{{ I18n.t("order_shipments.steps.step2.subheading") }}</p>

      <div class="py-4">
        <label class="pb-2">{{ I18n.t("order_shipments.steps.step2.labels.shipment_reference") }}</label>
        <input
          v-model="store.orderShipment.shipment_reference"
          type="text"
          class="form-control"
        >
      </div>
      <div class="py-4">
        <label class="pb-2">{{ I18n.t("order_shipments.steps.step2.labels.operating_instructions") }}</label>
        <textarea
          v-model="store.orderShipment.operating_instructions"
          rows="6"
          class="form-control"
        />
        <div class="form-text">
          {{ I18n.t("order_shipments.steps.step2.help.operating_instructions") }}
        </div>
      </div>
    </div>
  </div>
</template>
