import { SVGInjector } from '@tanem/svg-injector'

export const initMainMenu = () => {
  const menuIteml1 = document.querySelectorAll(".main-menu--desktop .menu-item-l0.menu-item-has-children")
  const menuIteml2 = document.querySelectorAll(".main-menu--desktop .menu-item-l1.menu-item-has-children")

  menuIteml1.forEach(item => {
    item.addEventListener("mouseover", () => {
      item.classList.add("menu-item-l1--open")
    })

    item.addEventListener("mouseout", () => {
      item.classList.remove("menu-item-l1--open")
    })
  })

  menuIteml2.forEach(item => {
    item.addEventListener("mouseover", () => {
      item.querySelector(".menu-l2")?.classList.add("menu-l2--open")
    })

    item.addEventListener("mouseleave", () => {
      item.querySelector(".menu-l2")?.classList.remove("menu-l2--open")
    })
  })
}

export function responsiveMenu() {
  if (window.innerWidth <= 1201) {
    const menuTrigger = document.querySelector(".header__action--menu")
    const mainMenu = document.querySelector(".main-menu")
    const mainMenuMobileScrollable = document.querySelector(".header-menu__main--scrollable")
    const mainMenuMobile = mainMenuMobileScrollable?.querySelector(".header-menu__main-menu")
    const closeMenu = document.querySelector(".close-mobile")
    const menuFirstLevel = document.querySelectorAll(".menu-item-l0.menu-item-has-children")
    const menuSecondLevel = document.querySelectorAll(".menu-item-l1.menu-item-has-children")
    const menuThirdLevel = document.querySelectorAll(".menu-item-l2.menu-item-has-children")

    if (mainMenu) {
      menuTrigger.addEventListener("click", (e) => {
        e.preventDefault()
        mainMenu.classList.remove("main-menu--desktop", "d-none")
        mainMenu.classList.add("main-menu--mobile", "d-block")
      })

      closeMenu.addEventListener("click", (e) => {
        e.preventDefault()
        mainMenu.classList.remove("main-menu--mobile", "d-block")
        mainMenu.classList.add("main-menu--desktop", "d-none")
      })

      menuFirstLevel.forEach(item => {
        item.querySelector("a").addEventListener("click", (e) => {
          e.preventDefault()
          item.classList.toggle("active")
          item.querySelectorAll(".menu-l1").forEach(list => {
            list.classList.toggle("active")
          })
        })
      })

      menuSecondLevel.forEach(item => {
        item.querySelector("a").addEventListener("click", (e) => {
          e.preventDefault()
          item.classList.toggle("active")
          item.querySelectorAll(".menu-l2").forEach(list => {
            list.classList.toggle("active")
          })
        })
      })

      menuThirdLevel.forEach(item => {
        item.querySelector("a").addEventListener("click", (e) => {
          e.preventDefault()
          item.classList.toggle("active")
          item.querySelectorAll(".menu-l3").forEach(list => {
            list.classList.toggle("active")
          })
        })
      })
    }
  } 
}

export function changeMenuBannersOnHover() {
  if (document.querySelector(".main-menu-banners")) {
    const categoryTitles = document.querySelectorAll(".menu-category-title > a")
    const images = [...document.querySelectorAll("img.menu-image")]

    for (let i = 0; i < categoryTitles.length; i++) {
      categoryTitles[i].addEventListener("mouseenter", function(e) {

        if (this.dataset.banner) {
          images[i].setAttribute("src", this.dataset.banner)
        }
      })
    }
  }
}

export function injectMenuSVGs() {
  SVGInjector(document.getElementsByClassName("menu-svg-inject"))
}