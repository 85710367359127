<template>
  <div class="history-order-lines-table">
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center mt-5 pt-5"
    >
      <vue-loaders-pacman
        color="#ddd"
        scale="2"
        class="d-block"
      />
    </div>

    <table
      v-if="!loading"
      class="table"
    >
      <tr>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.order_date") }}
        </th>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.code") }}
        </th>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.lot_code") }}
        </th>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.barcode") }}
        </th>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.quantity") }}
        </th>
        <th class="text-end">
          {{ i18n.t("history_order_lines_table.price") }}
        </th>
        <th class="text-end">
          {{ i18n.t("history_order_lines_table.line_gross_discount") }}
        </th>
        <th class="text-end">
          {{ i18n.t("history_order_lines_table.line_net_value") }}
        </th>
        <th class="text-center">
          {{ i18n.t("history_order_lines_table.status") }}
        </th>
      </tr>
      <tr
        v-for="line in lines"
        :key="line.code"
      >
        <td class="text-center">
          {{ line.order_date }}
        </td>
        <td class="text-center">
          {{ line.item_code }}
        </td>
        <td class="text-center">
          {{ line.lot_code }}
        </td>
        <td class="text-center">
          {{ line.barcode }}
        </td>
        <td class="text-center quantity">
          {{ line.quantity }}
        </td>
        <td class="text-end">
          {{ line.price }}
        </td>
        <td class="text-end">
          {{ line.line_gross_discount }}
        </td>
        <td class="text-end">
          <strong>{{ line.line_net_value }}</strong>
        </td>
        <td class="text-center">
          {{ line.status }}
        </td>
      </tr>
      <tr v-if="errorMessage">
        <td
          colspan="9"
          class="text-center error-message"
        >
          {{ errorMessage }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { i18n } from "../../lib/i18n"
import { getHistoryOrderLines } from '../../api/api'

export default {
  name: 'HistoryOrderLinesTable',
  props: {
    productId: {
      required: true,
      type: [String, Number]
    }
  },
  data() {
    return {
      i18n: i18n,
      loading: true,
      errorMessage: null,
      lines: []
    }
  },
  mounted() {
    this.loadHistoryOrderLines()
  },
  methods: {
    async loadHistoryOrderLines() {
      try {
        const response = await getHistoryOrderLines(this.productId)

        this.lines = response.data.history_order_lines
        this.loading = false
      } catch(error) {
        this.errorMessage = error.response.data.message
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .history-order-lines-table {
    min-height: 300px;

    table {
      font-size: 1.4rem;

      th {
        background-color: #f1f1f1;
        padding: 15px 10px;
      }

      td {
        padding: 20px 10px;
      }

      .quantity {
        font-size: 2rem;
      }
    }

    .error-message {
      color: #BB0907;
      font-weight: 600;
      font-size: 1.3rem;
    }
  }
</style>
