<script>
import { i18n } from "../../lib/i18n"
import TabNav from './TabNav.vue'
import TabPane from './TabPane.vue'
import { getProcessedOrderLines } from '../../api/api'

export default {
  name: "ShipmentTabs",
  components: {
    TabNav,
    TabPane
  },
  data() {
    return {
      lines: [],
      errorMessage: null,
      loading: true,
      tabs: [
        {
          code: "B2B.RSV",
          title: i18n.t("processed_order_lines_table.tab_nav.title.rsv"),
          tooltipDescription: i18n.t("processed_order_lines_table.tab_nav.tooltip_decription.rsv"),
          canCreateShipment: true
        },
        {
          code: "B2B.INB",
          title: i18n.t("processed_order_lines_table.tab_nav.title.inb"),
          tooltipDescription: i18n.t("processed_order_lines_table.tab_nav.tooltip_decription.inb"),
          canCreateShipment: false
        },
        {
          code: "B2B.SOC",
          title: i18n.t("processed_order_lines_table.tab_nav.title.soc"),
          tooltipDescription: i18n.t("processed_order_lines_table.tab_nav.tooltip_decription.soc"),
          canCreateShipment: false
        },
        {
          code: "B2B.BOR",
          title: i18n.t("processed_order_lines_table.tab_nav.title.bor"),
          tooltipDescription: i18n.t("processed_order_lines_table.tab_nav.tooltip_decription.bor"),
          canCreateShipment: false
        },
        {
          code: "B2B.CNC",
          title: i18n.t("processed_order_lines_table.tab_nav.title.cnc"),
          tooltipDescription: i18n.t("processed_order_lines_table.tab_nav.tooltip_decription.cnc"),
          canCreateShipment: false
        }
      ],
      selectedTab: {}
    }
  },
  mounted() {
    const params = new URL(document.location).searchParams
    const type   = params.get("type")

    if (type) {
      this.selectedTab = this.tabs.find(tab => tab.code == type) || this.tabs[0]
    } else {
      this.selectedTab = this.tabs[0]
    }

    this.loadProcessedOrderLines()
  },
  methods: {
    setTab(value) {
      this.selectedTab = this.tabs.filter(tab => tab.code == value)[0]
    },
    async loadProcessedOrderLines() {
      try {
        const response = await getProcessedOrderLines()

        this.lines = response.data.processed_order_lines
        this.loading = false
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <div data-test="tabs">
    <div
      v-if="errorMessage"
      class="text-center error-message p-5 m-auto"
    >
      {{ errorMessage }}
    </div>

    <ul class="nav nab-tabs d-flex fs-1">
      <TabNav
        v-for="tab in tabs"
        :key="tab.code"
        :tab="tab"
        :selected="selectedTab"
        @select="setTab"
      />
    </ul>
    <div class="tab-content overflow-auto py-4">
      <TabPane
        v-for="tab in tabs"
        :key="tab.code"
        :tab="tab"
        :loading="loading"
        :selected="selectedTab"
        :lines="lines"
      />
    </div>
  </div>
</template>

<style lang="scss">
  .error-message {
    color: #BB0907;
    font-weight: 600;
    font-size: 1.3rem;
    max-width: 00px;
  }
  .nab-tabs {
    border-bottom: 2px solid lighten(black, 90%);

    li {
      margin-bottom: -2px;
    }
  }
</style>
