<script setup>
import { defineProps, onMounted, watch } from 'vue'
import { useStore } from '../../stores/orderShipment.js'
import OrderShipmentSteps from './OrderShipmentSteps.vue'
import OrderShipmentPane from './OrderShipmentPane.vue'

// Store
const store = useStore()

// Props
const props = defineProps({
  id: {
    type: Number,
    required: true
  }
})

// Mounted
onMounted(async () => {
  store.loadOrderShipment(props.id)
  store.loadAddresses()
  store.loadPaymentMethods()
})
</script>

<template>
  <div class="shipment-wizard">
    <div
      v-if="store.orderShipmentLoading"
      class="row"
    >
      <div
        class="col-12 p-5 d-flex justify-content-center align-items-center"
        style="min-height: 400px;"
      >
        <vue-loaders-pacman
          color="#ddd"
          scale="3"
          class=""
        />
      </div>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-2 pe-4">
        <OrderShipmentSteps />
      </div>
      <div class="col-10 ps-4">
        <OrderShipmentPane />
      </div>
    </div>
  </div>
</template>
