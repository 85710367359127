<script setup>
import { i18n } from "../../lib/i18n"
import { ref, defineProps, watch, computed } from 'vue'
import { createOrderShipment } from '../../api/api'

const I18n = i18n

// Props
const props = defineProps({
  lines: {
    type: Object,
    default: []
  },
  selectedLines: {
    type: Object,
    default: {}
  }
})

// Data
const loading  = ref(false)
const disabled = ref(true)

// Watchers
watch(() => props.selectedLines, (selectedLines) => {
  disabled.value = (Object.keys(selectedLines).length === 0 || Object.values(selectedLines).every(value => !value))
}, { deep: true })

// Methods
const selectOrderShipmentLines = () => {
  let shipmentLines = []

  for (let lineId in props.selectedLines) {
    shipmentLines.push(props.lines.find(line => line.id == lineId))
  }

  return shipmentLines
}

const create = async () => {
  loading.value = disabled.value = true
  const lines = selectOrderShipmentLines()

  try {
    const response = await createOrderShipment(lines)

    loading.value = disabled.value = false

    window.location = response.data.redirect_to
  } catch(error) {
    loading.value = disabled.value = false
  }
}
</script>

<template>
  <button
    class="btn-standard d-flex align-items-center justify-content-center"
    :class="{ loading: loading }"
    :disabled="disabled"
    @click="create"
  >
    <vue-loaders-ball-beat
      v-if="loading"
      color="white"
      scale="0.4"
    />
    {{ I18n.t("processed_order_lines_table.tab_pane.new_shipment_button") }}
  </button>
</template>

<style lang="scss" scoped>
  button {
    transition: none;

    &:disabled {
      cursor: not-allowed;
      background-color: lighten(black, 80%);
      border: 2px solid lighten(black, 80%);

      &:active {
        transform: none;
      }
    }

    &.loading {
      padding-left: 0px;

      &:disabled {
        background-color: black;
        border: 2px solid black;
      }
    }
  }
</style>