<script setup>
import { i18n } from '../../lib/i18n'
import { useStore } from '../../stores/orderShipment.js'
import { storeToRefs } from 'pinia'

const I18n = i18n

// Props
const store = useStore()
const { orderShipment } = storeToRefs(store)
</script>

<template>
  <table class="table order-shipment-totals">
    <tbody>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.transportation") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(0) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.recycling_fees") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(0) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.clearance") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(0) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.insurance") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(0) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.banking_expenses") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(0) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.net_value") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(orderShipment.net_cost) }}
        </td>
      </tr>
      <tr>
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.discount") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(orderShipment.discounted_cost) }}
        </td>
      </tr>
      <tr class="final-price">
        <td class="p-4 ps-5 text-start">
          {{ I18n.t("order_shipments.order_shipment_totals.total") }}
        </td>
        <td class="text-end p-4 pe-5">
          {{ $filters.currency(orderShipment.final_cost) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/partials/_variables.scss';

table.order-shipment-totals {
  tr {
    border-bottom: 2px solid lighten(black, 90%);



    &:first-child {

        border-top: 2px solid lighten(black, 90%);

    }

    &:last-child {
      td {
        border-top: 2px solid lighten(black, 70%);
      }
    }

    &.final-price {
      color: $milano-red;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
}
</style>