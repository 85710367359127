import { initHeaderInfoSwiper } from "../components/swipers"

initHeaderInfoSwiper()

// Makes the header sticky. Use this together with the fixed class.

export function initStickyHeader() {
  const header = document.querySelector(".header")
  const headerInfoWrapper = header?.querySelector(".header-info--wrapper")
  const headerCenterWrapper = header?.querySelector(".header-center--wrapper")
  const body = document.body
  const html = document.documentElement
  const user = header?.querySelector(".header__action--user")

  const height = Math.max(body.scrollHeight, body.offsetHeight,
    html.clientHeight, html.scrollHeight, html.offsetHeight)

  if (window.pageYOffset !== 0) { window.scrollTo (0, 0) }

  function stickyHeader() {
    if (header) {

      if (height > window.screen.height && window.scrollY > 100) {
        header.classList.add("header--sticky")
        headerInfoWrapper?.classList.add("d-none")
        headerCenterWrapper?.classList.add("d-none")
      }
      else if (height > window.screen.height && window.scrollY === 0) {
        header.classList.remove("header--sticky")
        headerInfoWrapper?.classList.remove("d-none")
        headerCenterWrapper?.classList.remove("d-none")
      }
    }
  }

  window.addEventListener("scroll", stickyHeader)
}

export function initHeaderLanguages() {
  const headerLanguages = document.querySelector(".header__languages")

  headerLanguages.addEventListener("mouseenter", function() {
    this.querySelector(".header__languages").classList.remove("d-none")
    this.querySelector(".header__languages").classList.add("header__languages--open")
  })
  headerLanguages.addEventListener("mouseleave", function() {
    this.querySelector(".header__languages").classList.add("d-none")
    this.querySelector(".header__languages").classList.remove("header__languages--open")
  })
}

export function initTogglerSiteTheme() {
  let body = document.querySelector("body")
  let switcher = document.querySelector(".icon-switch")
  let t = null;

  if (switcher) {
  switcher.addEventListener("click", function () {
    clearTimeout(t)
    t = setTimeout(
      function(){
        if (body.className == "site--games") {
          body.classList.replace("site--games","site--wines")
        }
        else {
          body.classList.replace("site--wines","site--games")
        }
      },
      100
    )
  })
 }
}